

import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdReports } from "../../../Redux/ReportsReducers";
import { disableAd } from "../../../Redux/ReportsReducers";
import { rejectAd } from "../../../Redux/ReportsReducers";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { GrClose } from "react-icons/gr";
import Paper from "@mui/material/Paper";

export default function CustomizedTables() {
  const dispatch = useDispatch();
  const { AdReports, status } = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(getAdReports());
  }, [dispatch]);

  // Prepare rows and columns for the DataGrid
  const columns = [
    { field: "title", headerName: "Title", width: 150, editable: false },
    { field: "city", headerName: "City", width: 90 },
    { field: "reason", headerName: "Reason", width: 160 },
    { field: "details", headerName: "Details", width: 220 },
    { field: "status", headerName: "Status", width: 90 },
    {
      field: "action",
      headerName: "Action",
      width: 90,
      renderCell: (params) => {
        return (
          <button
            onClick={() =>
              dispatch(disableAd(params.row._id, params.row.reported_id._id))
            }
            className="text-red-600  font-bold border-1  hover:cursor-pointer p-2 "
          >
            Remove
          </button>
        );
      },
    },
    {
      field: "reject",
      headerName: "Reject",
      width: 90,
      renderCell: (params) => {
        return (
          <div className="flex h-full w-full justify-center items-center">
            <GrClose
              onClick={() => {
                dispatch(rejectAd(params.row.id));
              }}
              className="cursor-pointer text-red-600"
            />
          </div>
        );
      },
    },
  ];

  // Filter the reports to show only pending status
  const filteredReports = AdReports.filter(
    (report) => report.status === "pending"
  );

  // Prepare rows for the DataGrid
  const rows = filteredReports.map((report) => ({
    id: report._id,
    title: report.reported_id.title,
    city: report.reported_id.city,
    reason: report.reason,
    details: report.details,
    status: report.status,
    reported_id: report.reported_id,
  }));

  return (
    <Paper sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
      {status === "loading" ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5} // Items per page
          rowsPerPageOptions={[5]} // Options for number of items per page
          disableSelectionOnClick
          components={{
            Toolbar: GridToolbar, // Add default filter toolbar
          }}
          pagination
        />
      )}
    </Paper>
  );
}
