import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPublishedNews } from "../../../Redux/NewsReducer";
const AllPublishNews = () => {
  const dispatch = useDispatch();
  const { publishedNews } = useSelector((state) => state.news);

  const [News, setNews] = useState([]);

  useEffect(() => {
    dispatch(getPublishedNews());
  }, [dispatch]);

  useEffect(() => {
    setNews(publishedNews);
  }, [publishedNews]);

  return (
    <div>
      <h1 className="text-2xl text-gray-700 font-semibold text-center">
        All Publish News
      </h1>
      <select
        className="block w-28pm start bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 shadow-sm"
        onChange={(e) => {
          if (e.target.value === "ALL") {
            setNews(publishedNews);
          } else {
            const filtered = publishedNews.filter(
              (news) => news.category === e.target.value
            );
            setNews(filtered);
          }
        }}
        name="filter"
        id=""
      >
        <option value="ALL">All</option>
        <option value="NEWS">News</option>
        <option value="REVIEWS">Reviews</option>
      </select>
      <div className="grid grid-cols-3 justify-between gap-4 my-3 mx-2  pb-5 text-gray-500 ">
        {publishedNews &&
          News.map((news) => (
            <div className="hover:scale-105 duration-150 h-[260px]  space-y-4 pb-4 bg-white shadow-md shadow-gray-900 ">
              <Link
                to={`/newsdetails/${news.title}/${news._id}`}
                key={news._id}
              >
                <div className="hover:text-gray-400 ">
                  <img
                    src={news.image1}
                    alt="news images"
                    className="  object-center bg-no-repeat h-[180px] w-[400px] "
                  />
                  <div className="text-sm px-2 space-y-1 mx-3 mt-2">
                    <h1 className="font-bold">BRAND : {news.brand}</h1>
                    <h1 className="font-bold">
                      CATEGORY :{" "}
                      <span className="text-green-400">{news.category}</span>
                    </h1>
                    <p className="font-semibold  line-clamp-1">{news.title}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AllPublishNews;
