import React, { useEffect, useState } from "react";
import ReportedAds from "./ReportedAds";
import ReportesUsers from "./ReportesUsers";
import { useSelector } from "react-redux";

const Reports = () => {

  const [isOpen, setIsOpen] = useState(true);

  const {isUpdate} = useSelector(state => state.reports)

  useEffect(()=>{
    if(isUpdate) window.location.reload("/")
  },[isUpdate])

  return (
    <div className="w-full h-full bg-white p-5 rounded-lg shadow-sm">
      <div className="flex flex-row justify-between items-center w-60 mb-4">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          className="bg-gray-500 text-white shadow-sm shadow-gray-800 p-2 rounded-md hover:bg-gray-400 hover:cursor-pointer"
        >
          User Reports
        </div>
        <div
          onClick={() => {
            setIsOpen(false);
          }}
           className="bg-gray-500 text-white shadow-sm shadow-gray-800 p-2 rounded-md hover:bg-gray-400 hover:cursor-pointer"
        >
          Ads Reports
        </div>
      </div>
      {isOpen && (
        <div>
          <h1 className="text-xl font-semibold text-gray-800">
            Reported Users
          </h1>
          <ReportesUsers />
        </div>
      )}

      {!isOpen && (
        <div>
          <h1 className="text-xl font-semibold text-gray-800">Reported Ads</h1>
          <ReportedAds />
        </div>
      )}
    </div>
  );
};

export default Reports;
