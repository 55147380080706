import { React, useEffect, useState } from "react";

const Card3 = (props) => {

  
  const [ads, setAds] = useState([]);

  useEffect(() => {
    const filtered =
      props.totalAds.length > 4 ? props.totalAds.slice(0, 3) : props.totalAds;

    setAds(filtered);
  }, [props.totalAds, ads]);
  return (
    <div className="z-10 bg-black/90 inset-0 w-full h-screen absolute flex flex-col justify-center items-center p-3 ">
      <button
        className="p-2 text-center font-bold bg-gray-500 text-white self-start"
        onClick={() => {
          props.setAdCard(false);
        }}
      >
        X
      </button>
      <div>
        {props.totalAds.length > 0 ? (
          <div className=" rounded-sm shadow-sm p-2 grid md:grid-cols-4 grid-cols-1 z-20 gap-2 w-auto ">
            {ads.map((ad) => (
              <div className="bg-white shadow-gray shadow-xl p-2 h-[50vh]">
                <img
                  src={ad.images[0]}
                  alt="ad-photo"
                  className="h-[40%] m-2"
                />
                <div className="grid grid-cols-2">
                  <label className="font-bold text-gray-500 text-sm">
                    Title
                  </label>
                  <h1>{ad.title}</h1>
                  <label className="font-bold text-gray-500 text-sm">
                    Description
                  </label>
                  <h1>{ad.description}</h1>
                  <label className="font-bold text-gray-500 text-sm">
                    City
                  </label>
                  <h1>{ad.city}</h1>
                  <label className="font-bold text-gray-500 text-sm">
                    Brand
                  </label>
                  <h1>{ad.brand}</h1>

                  <label className="font-bold text-gray-500 text-sm">
                    Price
                  </label>
                  <h1>{ad.price}</h1>
                  <label className="font-bold text-gray-500 text-sm">
                    Condition
                  </label>
                  <h1>{ad.condition}</h1>
                </div>
              </div>
            ))}
            {props.totalAds.length > 4 && <button>See More</button>}
          </div>
        ) : (
          <h1 className="bg-white text-4xl shadow-gray-500 shadow-lg p-40 rounded-lg">
            No Ads Found
          </h1>
        )}
      </div>
    </div>
  );
};

export default Card3;
