import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import {
  getAdminLogs,
  getUserLogs,
  getNewsLogs,
  getReviewsLogs,
  getAdsLogs,
  deleteAdminLog,
  reset,
} from "../../Redux/logsReducer";

const Logs = () => {
  const dispatch = useDispatch();
  const { adminLogs, userLogs, newsLogs, reviewsLogs, adsLogs, status, isDeleted, message } =
    useSelector((state) => state.logs);

  const [isDropdownOpen, setIsDropdownOpen] = useState([true, false, false, false, false]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const toggleDropdown = (index) => {
    const newStates = isDropdownOpen.map((state, i) => i === index);
    setIsDropdownOpen(newStates);
  };

  // Fetch logs on component mount
  useEffect(() => {
    dispatch(getAdminLogs());
    dispatch(getUserLogs());
    dispatch(getNewsLogs());
    dispatch(getReviewsLogs());
    dispatch(getAdsLogs());
  }, [dispatch]);

  // Function to map rows dynamically based on log type
  const getRowMapper = (logType) => {
    switch (logType) {
      case "admin":
        return (logs) =>
          logs.map((log, index) => ({
            id: index + 1,
            name: log.name,
            activityTime: log.activityTime.split("T")[0],
            operation: log.operation,
            delete: log._id, // ID for deletion
          }));
      case "user":
        return (logs) =>
          logs.map((log, index) => ({
            id: index + 1,
            activityTime: log.activityTime.split("T")[0],
            ip: log.ip,
            region: log.region,
            url: log.url,
          }));
      case "news":
        return (logs) =>
          logs.map((log, index) => ({
            id: index + 1,
            activityTime: log.activityTime.split("T")[0],
            // headline: log.headline,
            region: log.region,
            url: log.url,
            ip: log.ip,
          }));
      case "reviews":
        return (logs) =>
          logs.map((log, index) => ({
            id: index + 1,
            activityTime: log.activityTime.split("T")[0],
            region: log.region,
            url: log.url,
            ip: log.ip,
          }));
      case "ads":
        return (logs) =>
          logs.map((log, index) => ({
            id: index + 1,
            activityTime: log.activityTime.split("T")[0],
            region: log.region,
            ip: log.ip,
            url: log.url,
          }));
      default:
        return () => [];
    }
  };

  // Handle delete success notification
  useEffect(() => {
    if (isDeleted) {
      toast.success(message);
      dispatch(reset());
    }
  }, [isDeleted, message, dispatch]);

  // Update rows and columns dynamically
  useEffect(() => {
    let logType = "";
    if (isDropdownOpen[0]) logType = "admin";
    if (isDropdownOpen[1]) logType = "user";
    if (isDropdownOpen[2]) logType = "news";
    if (isDropdownOpen[3]) logType = "reviews";
    if (isDropdownOpen[4]) logType = "ads";

    const mapRows = getRowMapper(logType);
    switch (logType) {
      case "admin":
        setRows(mapRows(adminLogs));
        setColumns([
          { field: "name", headerName: "Name", width: 200 },
          { field: "activityTime", headerName: "Activity Time", width: 230 },
          { field: "operation", headerName: "Operation", width: 230 },
          {
            field: "delete",
            headerName: "Delete",
            width: 230,
            renderCell: (params) => (
              <button
                onClick={() => dispatch(deleteAdminLog(params.row.delete))}
                className=" px-3 py-1 rounded text-red-600 "
              >
                Delete
              </button>
            ),
          },
        ]);
        break;
      case "user":
        setRows(mapRows(userLogs));
        setColumns([
          { field: "activityTime", headerName: "Date", width: 200 },
          { field: "ip", headerName: "IP", width: 200 },
          { field: "region", headerName: "Region", width: 200 },
          { field: "url", headerName: "URL", width: 330 },
        ]);
        break;
      case "news":
        setRows(mapRows(newsLogs));
        setColumns([
          { field: "activityTime", headerName: "Date", width: 200 },
          { field: "region", headerName: "Region", width: 250 },
          { field: "url", headerName: "URL", width: 350 },
          { field: "ip", headerName: "IP", width: 300 },
        ]);
        break;
      case "reviews":
        setRows(mapRows(reviewsLogs));
        setColumns([
          { field: "activityTime", headerName: "Date", width: 200 },
          { field: "region", headerName: "Region", width: 300 },
          { field: "url", headerName: "URL", width: 250 },
          { field: "ip", headerName: "IP", width: 250 },
        ]);
        break;
      case "ads":
        setRows(mapRows(adsLogs));
        setColumns([
          { field: "activityTime", headerName: "Date", width: 200 },
          { field: "ip", headerName: "IP", width: 250 },
          { field: "region", headerName: "Region", width: 250 },
          { field: "url", headerName: "URL", width: 350 },
        ]);
        break;
      default:
        setRows([]);
        setColumns([]);
    }
  }, [isDropdownOpen, adminLogs, userLogs, newsLogs, reviewsLogs, adsLogs]);

  return (
    <div>
      <h1 className="top-heading text-3xl font-semibold text-blue-950 px-3 mb-2 mt-2">History Log</h1>

      {/* Dropdown Toggles */}
      <button onClick={() => toggleDropdown(0)} className="btn">
        Admin Logs
      </button>
      <button onClick={() => toggleDropdown(1)} className="btn">
        Mobile Logs
      </button>
      <button onClick={() => toggleDropdown(2)} className="btn">
        News Logs
      </button>
      <button onClick={() => toggleDropdown(3)} className="btn">
        Review Logs
      </button>
      <button onClick={() => toggleDropdown(4)} className="btn">
        Ads Logs
      </button>

      {/* Data Grid */}
      <div style={{ height: 470, width: "82%" ,marginTop:"20px"}}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 20]}
          components={{ Toolbar: GridToolbar }}
          loading={status === "loading"}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              background: "rgb(75,85,99)",
              // color: "white",
            },
          }}
        />
      </div>
    </div>
  );
};

export default Logs;
//logs.jsx