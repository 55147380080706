// export default AllMobiles;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMobiles, deleteSingleMobile } from "../../../Redux/AllMobiles";
import { getBrands } from "../../../Redux/BrandsReducer";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import AddMobileForm from "../Mobiles/AddMobileForm";
import { DataGrid } from "@mui/x-data-grid";


const AllMobiles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state) => state.user);
  const { brands } = useSelector((state) => state.brands);
  const { mobiles, status } = useSelector((state) => state.mobiles);

  const [mobileBrands, setMobileBrands] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [addMobile, setAddMobile] = useState(false);

  useEffect(() => {
    if (!brands.length) dispatch(getBrands());
    setMobileBrands(brands);
  }, [brands, dispatch]);

  useEffect(() => {
    if (!mobiles.length) dispatch(getAllMobiles());
  }, [mobiles, dispatch]);

  const deleteMobile = (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this mobile?"
    );
    if (confirmed) {
      dispatch(deleteSingleMobile(id, currentUser));
      alert("Mobile deleted!");
    }
  };

  const toggleAddMobile = () => setAddMobile((prev) => !prev);

  const columns = [
  
    { field: "brand", headerName: "Brand", width: 150 },
    { field: "model", headerName: "Model", width: 250 },
    {
      field: "createdOn",
      headerName: "Release Date",
      width: 250,
    
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <button
            onClick={() => navigate(`/MobileDetails/${params.row.id}`)}
            className="text-blue-500 underline mr-2"
          >
            Edit
          </button>
          <button
            onClick={() => deleteMobile(params.row.id)}
            className="text-red-500 underline"
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  const rows = mobiles.map((mobile, index) => ({
    id: mobile._id,
    brand: mobile.brand,
    model: mobile.model,
    createdOn: mobile.createdOn.split("T")[0],
  }));

  return (
    <div>
      {addMobile ? (
        <AddMobileForm
          isVisible={toggleAddMobile}
          toggleVisibility={setAddMobile}
        />
      ) : (
        <div className="ml-5 pb-9">
          <div className="text-end space-x-2">
            <button
              className="w-8 h-8 bg-gray-700 text-white rounded-full text-xl text-center pb-1 m-1"
              onClick={() => setAddMobile(true)}
            >
              +
            </button>
          </div>
        </div>
      )}

      <Box>
        {status === "loading" ? (
          <div className="flex justify-center py-4 text-black">Loading...</div>
        ) : (

          <DataGrid sx={{ height: 500, margin: "auto", marginLeft: "0px" }}
            rows={rows}
            columns={columns}
            pageSizeOptions={[5, 10, 20]}
            paginationModel={{
              page: currentPage - 1,
              pageSize: 10,
            }}
            onPaginationModelChange={(model) => setCurrentPage(model.page + 1)}
            disableRowSelectionOnClick
           className="w-96 md:w-[65vw]"
          />
          
        )}
      </Box>
    </div>
  );
};

export default AllMobiles;
