// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const AnalyticsReducer = createSlice({

  //STATE NAME
  name: "analytics",

  //STATE INITIAL VALUE
  initialState: {
    totalMobiles: 0,
    totalNews: 0,
    totalReviews: 0,
    totalUsers: 0,
    status:"idle",
    error: null,
  },

  //REDUCER FUNCTIONS
  reducers: {
    totalMobilesSuccess(state,action) {
      state.totalMobiles = action.payload.totalMobiles;
    },
    totalNewsSuccess(state, action) {
     
      state.totalNews = action.payload.totalNews;
    },

    totalReviewsSuccess(state, action) {
      
      state.totalReviews = action.payload.totalReviews;
    },
    totalUsersSuccess(state,action) {

        state.totalUsers = action.payload.totalUsers;
    }
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
 totalMobilesSuccess, totalNewsSuccess, totalReviewsSuccess, totalUsersSuccess
} = AnalyticsReducer.actions;

//EXPORT REDUCER

export default AnalyticsReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS



