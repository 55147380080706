import React, { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../../../Redux/BrandsReducer";
import { editNews } from "../../../Redux/NewsReducer";
import ImageUpload1 from "../../ImageUpload1";
import ImageUpload2 from "../../ImageUpload2";
import { toast } from "react-toastify";

const EditForPublishNews = ({ handleEditPageVisibility, id, data }) => {
  const { token, currentUser } = useSelector((state) => state.user);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const { imageUrl1, imageUrl2, isLoading, uploaded1, uploaded2 } = useSelector(
    (state) => state.imageUplaod
  );
  const [images, setImages] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [brand, setBrand] = useState("");
  const { brands } = useSelector((state) => state.brands);
  const dispatch = useDispatch();
  const [mobileBrands, setMobileBrands] = useState("");

  useEffect(() => {
    if (brands.length === 0) {
      dispatch(getBrands());
    }
  }, []);

  useEffect(() => {
    setMobileBrands(brands);
  }, [brands]);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setDescription(data.description);
      setCategory(data.category);
      setBrand(data.brand);
      setImage1(data.image1);
      setImage2(data.image2);
    }
  }, [data]);

  function handleTitle(e) {
    setTitle(e.target.value);
  }

  function handleDescription(content) {
    setDescription(content);
  }

 
  function handleCategory(e) {
    setCategory(e.target.value);
  }

  function handleBrand(e) {
    setBrand(e.target.value);
  }

  function submitHandler(e) {
    e.preventDefault();

    // Send as JSON
    const payload = {
      title,
      description,
      brand,
      category,
      name: currentUser.name,
      role: currentUser.role,
      image1: imageUrl1 ? imageUrl1 : image1,
      image2: imageUrl2 ? imageUrl2 : image2,
    };

    dispatch(editNews(payload, id, token));
    toast.success("News updated successfully");
    handleEditPageVisibility(false);
  }

  return (
    <div className="z-10 bg-black/80 inset-0 w-full h-screen absolute ">
      <div className="bg-white w-[500px] mx-auto rounded-sm px-5 my-9" style={{minHeight:"90%", minWidth:"80%"}}>
        <button
          onClick={handleEditPageVisibility}
          className="pt-2"
          title="Go back"
        >
          <IoMdArrowRoundBack />
        </button>
        <h1 className="text-center font-semibold text-2xl ">Add Details</h1>
        <form action="" className="space-y-3 text-sm " onSubmit={submitHandler}>
          <select
            required
            value={brand}
            onChange={handleBrand}
            className="bg-gray-700 text-white px-2 text-[12px] w-[12rem] outline-none py-2 rounded-sm"
          >
            <option value="" className="gird grid-cols-4">
              Select a Brand
            </option>
            {mobileBrands && mobileBrands.length > 0 ? (
              mobileBrands.map((item) => (
                <option key={item._id} value={item.brand}>
                  {item.brand}
                </option>
              ))
            ) : (
              <option disabled>Loading Brands...</option>
            )}
            <option value="">Others</option>
          </select>

          <select
            required
            onChange={handleCategory}
            className="bg-gray-700 ml-4 w-[12rem] text-[12px] text-white px-2 outline-none py-2 rounded-sm"
            value={category}
          >
            <option value="">Select a category</option>
            <option value="NEWS">NEWS</option>
            <option value="REVIEWS">REVIEWS</option>
          </select>

          <input
            type="text"
            name="title"
            value={title}
            onChange={handleTitle}
            placeholder="Please add video url"
            className="w-full h-10 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />
          <div className="h-[350px]">
            <ReactQuill
              required
              theme="snow"
              onChange={handleDescription}
              value={description}
              className="h-[90%] "
            />
          </div>

          <ImageUpload1 className=" text-sm text-gray-700 outline-none  rounded-sm " />

          <ImageUpload2 className=" text-sm text-gray-700 outline-none  rounded-sm " />

          {isLoading == "loading" ? (
            <button>Wait!!</button>
          ) : (
            <button
              type="submit"
              className="w-full h-12 mt-4 text-sm border-gray-400 rounded-sm bg-gray-800 text-white hover:bg-gray-900 duration-300"
            >
              Submit
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default EditForPublishNews;
