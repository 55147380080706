// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const newsReducer = createSlice({
  //STATE NAME
  name: "news",

  //STATE INITIAL VALUE
  initialState: {
    message: "",
    status: "idle",
    error: null,
    isUpdated: false,
    singleNews: {},
    publishedNews: [],
    pendingNews: [],
    rejectedNews: [],
  },

  //REDUCER FUNCTIONS
  reducers: {
    newsRequest(state) {
      state.status = "loading";
    },
    newsSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.msg;
      state.isUpdated = true;
    },
    singleNewsRequest(state) {
      state.status = "loading";
    },
    singleNewsSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.singleNews = action.payload.singleNews;
    },
    updateSingleNewsRequest(state) {
      state.status = "loading";
    },
    updateSingleNewsSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.isUpdated = true;
    },
    publishedNewsSuccess(state, action) {
      state.status = "succeeded";
      state.publishedNews = action.payload.publishedNews;
    },
    pendingNewsSuccess(state, action) {
      state.status = "succeeded";
      state.pendingNews = action.payload.pendingNews;
    },
    rejectedNewsSuccess(state, action) {
      state.status = "succeeded";
      state.rejectedNews = action.payload.rejectedNews;
    },
    newsFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
  newsRequest,
  newsSuccess,
  newsFailure,
  pendingNewsSuccess,
  rejectedNewsSuccess,
  publishedNewsSuccess,
  singleNewsRequest,
  singleNewsSuccess,
  updateSingleNewsRequest,
  updateSingleNewsSuccess,
} = newsReducer.actions;

//EXPORT REDUCER

export default newsReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const postNews = (newNews, token) => async (dispatch) => {
  dispatch(newsRequest());

  try {
    if (newNews.category === "REVIEWS") {
      const response = await fetch(
        `https://mm.magma3c.com/reviews/createReview`,
        {
          method: "POST",
          body: JSON.stringify(newNews),
          headers: { "Content-Type": "application/json" },
        }
      );

      const responseData = await response.json();

      dispatch(newsSuccess({ msg: responseData.message }));
    } else {
      const response = await fetch(`https://mm.magma3c.com/news/createNews`, {
        method: "POST",
        body: JSON.stringify(newNews),
        headers: { "Content-Type": "application/json" },
      });

      const responseData = await response.json();

      dispatch(newsSuccess({ msg: responseData.message }));
    }
  } catch (error) {
    dispatch(newsFailure(error.message));
  }
};

export const getPublishedNews = () => async (dispatch) => {
  dispatch(newsRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/allNews`);

    const responseData = await response.json();

    dispatch(publishedNewsSuccess({ publishedNews: responseData }));
  } catch (error) {
    dispatch(newsFailure(error.message));
  }
};

export const getPendingNews = () => async (dispatch) => {
  dispatch(newsRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/news/pendingNews`);

    const responseData = await response.json();

    dispatch(pendingNewsSuccess({ pendingNews: responseData }));
  } catch (error) {
    dispatch(newsFailure(error.message));
  }
};

export const getRejectedNews = () => async (dispatch) => {
  dispatch(newsRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/news/rejectedNews`);

    const responseData = await response.json();

    dispatch(rejectedNewsSuccess({ rejectedNews: responseData }));
  } catch (error) {
    dispatch(newsFailure(error.message));
  }
};

export const getSingleNews = (id) => async (dispatch) => {
  dispatch(singleNewsRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/news/${id}`);

    const responseData = await response.json();

    dispatch(singleNewsSuccess({ singleNews: responseData }));
  } catch (error) {
    dispatch(newsFailure(error.message));
  }
};

export const updateSingleNews = (id, status) => async (dispatch) => {
  dispatch(updateSingleNewsRequest());

  try {
    const response = await fetch(
      `https://mm.magma3c.com/news/changeStatus/${id}`,
      {
        method: "PUT",
        body: JSON.stringify({ Status: status }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const responseData = await response.json();

    dispatch(updateSingleNewsSuccess({ message: responseData.message }));
  } catch (error) {
    dispatch(newsFailure(error.message));
  }
};

export const editNews = (data, id, token) => async (dispatch) => {
  dispatch(updateSingleNewsRequest());
  

  try {
    const response = await fetch(
      `https://mm.magma3c.com/news/updateNews/${id}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          authorization: `bareer ${token}`,
        },
      }
    );

    const responseData = await response.json();

    dispatch(updateSingleNewsSuccess({ message: "successfully updated !!!" }));
  } catch (error) {
    dispatch(newsFailure(error.message));
  }
};
