import React, { useEffect, useState } from "react";
import { MdEdit, MdDeleteForever } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../Redux/UsersReducer.js";
import { deleteUser } from "../../../Redux/UserReducer.js";
import UserEditForm from "./UserEditForm.jsx";
import { FaRegEdit } from "react-icons/fa";

const UsersDetails = () => {
  const [isUserEdited, setUserEdit] = useState(false);
  const [userID, setUserID] = useState("");
  const [showPopup, setShowPopup] = useState(false); // State for popup visibility
  const [selectedUser, setSelectedUser] = useState(null); // Track user to delete

  const cellClass = `2xl:w-[240px] xl:w-[120px] lg:w-[100px] md:w-[100px] w-[40px] 
    2xl:py-4 xl:px-2 text-[17px] md:text-[14px] 2xl:text-[1.6rem] line-clamp-1 h-10 text-center 2xl:line-clamp-none`;

  const { users, status } = useSelector((state) => state.users);
  const { isEdited } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (isEdited) window.location.reload("/");
  }, [isEdited]);

  const handleDelete = () => {
    if (selectedUser) {
      dispatch(deleteUser(selectedUser.uid, selectedUser._id));
      setShowPopup(false);
    }
  };

  const openPopup = (user) => {
    setSelectedUser(user);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedUser(null);
  };

  return (
    <div>
      {isUserEdited && <UserEditForm userID={userID} toggle={()=>setUserEdit(false)} />}
      
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">Confirm Deletion</h3>
            <p className="text-gray-700 mb-6">
              Are you sure you want to delete this item?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={closePopup}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="w-full mt-2 bg-white shadow-md overflow-x-hidden">
        <div className="flex justify-between items-center bg-gray-600 text-white font-bold">
          <p className={cellClass}>Name</p>
          <p className={cellClass}>Email</p>
          <p className={cellClass}>Role</p>
          <p className={cellClass}>Login time</p>
          <p className={cellClass}>Logout time</p>
          <p className={cellClass}>User Activity</p>
        </div>

        <div className="overflow-y-auto px-1 border">
          {status === "loading"
            ? "Loading !!!"
            : users.map((user) => (
                <div
                  key={user.uid}
                  className="flex justify-between items-center border-b py-2"
                >
                  <p className={cellClass}>{user.name}</p>
                  <p className={cellClass}>{user.email}</p>
                  <p className={cellClass}>{user.role}</p>
                  <p className={cellClass}>03:49:94</p>
                  <p className={cellClass}>47:48:00</p>
                  <Link
                    to={`/useractivity/${user.uid}+${user._id}`}
                    className={cellClass}
                  >
                    User Activity
                  </Link>
                  <button onClick={() => openPopup(user)}>
                    <MdDeleteForever size={25} className="text-red-500" />
                  </button>
                  <button onClick={() => {setUserID(user.uid) ; setUserEdit(true)  }}>
                  <FaRegEdit size={20} />
                  </button>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default UsersDetails;