import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { FaMobileScreenButton } from "react-icons/fa6";
import { IoMdPricetag } from "react-icons/io";
import { GiNewspaper } from "react-icons/gi";
import { FaUsers } from "react-icons/fa6";
import { MdAdsClick } from "react-icons/md";
import { HiLibrary } from "react-icons/hi";
import { MdOutlineLogout } from "react-icons/md";
import { TbLogs } from "react-icons/tb";
import { GiSellCard } from "react-icons/gi";
import { AiFillBell } from "react-icons/ai";
import { GoReport } from "react-icons/go";

const MenuSideBar = ({ setSidebarOpen, handleLogout }) => {
  const { currentUser } = useSelector((state) => state.user);

  const routes = [
    { path: "/", label: "Dashboard", icon: MdDashboard },
    { path: "/allmobiles", label: "Mobiles", icon: FaMobileScreenButton },
    {
      path: "/mobileBrandsMainSec",
      label: "Mobile Brands",
      icon: IoMdPricetag,
    },
    { path: "/mainNewsContainer", label: "News", icon: GiNewspaper },
    { path: "/users", label: "Staff Users", icon: FaUsers },
    { path: "/ads", label: "Ads", icon: MdAdsClick },
    { path: "/glossary", label: "Glossary", icon: HiLibrary },
    { path: "/logs", label: "Logs", icon: TbLogs },
    { path: "/buyandsellcontainer", label: "Buy and Sell", icon: GiSellCard },
    { path: "/notification", label: "Notifications", icon: AiFillBell },
    { path: "/reports", label: "Complaints", icon: GoReport },
  ];

  // Filter out routes based on user role

  const filteredRoutes = routes.filter((route) => {
    // Hide specific routes if the role is 'publisher'
    if (currentUser.role === "publisher") {
      return (
        route.path !== "/users" &&
        route.path !== "/ads" &&
        route.path !== "/glossary" &&
        route.path !== "/mobileBrandsMainSec" &&
        route.path !== "/allmobiles" &&
        route.path !== "/logs" &&
        route.path !== "/buyandsellcontainer" &&
        route.path !== "/notification" &&
        route.path !== "/reports"
      );
    } else if (currentUser.role === "mobileseditor") {
      return (
        route.path !== "/users" &&
        route.path !== "/ads" &&
        route.path !== "/glossary" &&
        route.path !== "/mainNewsContainer" &&
        route.path !== "/buyandsellcontainer" &&
        route.path !== "/logs" &&
        route.path !== "/mobileBrandsMainSec" &&
        route.path !== "/notification" &&
        route.path !== "/reports"
      );
    } else if (currentUser.role === "glossaryeditor") {
      return (
        route.path !== "/users" &&
        route.path !== "/ads" &&
        route.path !== "/mainNewsContainer" &&
        route.path !== "/mobileBrandsMainSec" &&
        route.path !== "/allmobiles" &&
        route.path !== "/logs" &&
        route.path !== "/buyandsellcontainer" &&
        route.path !== "/notification" &&
        route.path !== "/reports"
      );
    } else if (currentUser.role === "manager") {
      return route.path !== "/users";
    } else if (currentUser.role === "adseditor") {
      return (
        route.path !== "/users" &&
        route.path !== "/mainNewsContainer" &&
        route.path !== "/glossary" &&
        route.path !== "/mobileBrandsMainSec" &&
        route.path !== "/allmobiles" &&
        route.path !== "/logs" &&
        route.path !== "/buyandsellcontainer" &&
        route.path !== "/notification" &&
        route.path !== "/reports"
      );
    } else if (currentUser.role === "buyselleditor") {
      return (
        route.path !== "/users" &&
        route.path !== "/mainNewsContainer" &&
        route.path !== "/glossary" &&
        route.path !== "/mobileBrandsMainSec" &&
        route.path !== "/allmobiles" &&
        route.path !== "/logs" &&
        route.path !== "/ads" &&
        route.path !== "/notification" &&
        route.path !== "/reports"
      );
    } else {
      return route;
    }
  });

  const location = useLocation();

  const handleRemoveMenu = () => {
    setSidebarOpen(false);
  };
  return (
    <div className="px-4 max-w-[600px]">
      <button
        onClick={handleRemoveMenu}
        className="ml-40 font-bold hover:shadow-md duration-300 w-8 mb-3 text-gray-900 "
      >
        x
      </button>
      <div className="text-gray-900 mb-3 " onClick={handleRemoveMenu}>
        <Link
          to="/"
          className="font-bold text-[23px]  px-2 hover:text-gray-700"
        >
          Mobile<span className="font-normal">Mate</span>
        </Link>
      </div>
      <div className="MenuSideBarContainer">
        {filteredRoutes.map((route,index) => (
          <Link
            to={route.path}
            key={index}
            className={` MenuSideBarbtn button ${
              location.pathname === route.path ? "active" : ""
            }`}
            onClick={handleRemoveMenu}
          >
            {React.createElement(route.icon, { size: 15 })}
            <span>{route.label}</span>
          </Link>
        ))}
        <button className="sideBarbutton button " onClick={handleLogout}>
          <span>{<MdOutlineLogout size={25} />}</span>
          <span>Logout </span>
        </button>
      </div>
    </div>
  );
};

export default MenuSideBar;
