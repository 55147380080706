import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";

import {
  disableClient,
  getClients,
  getTotalAds,
} from "../../../Redux/BuyandSell";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { searchClientByEmailResetError } from "../../../Redux/BuyandSell";
import Card3 from "../Card3";
import Carddummy from "../../Carddummy";

const Clients = () => {
  const dispatch = useDispatch();

  const { clients, totalAds, isUpdated, error } = useSelector(
    (state) => state.buyAndSell
  );

  useEffect(() => {
    if (!clients || clients.length === 0) {
      console.error("Clients data is missing");
      dispatch(getClients());
    }
  }, [clients, dispatch]);

  useEffect(() => {
    if (isUpdated) window.location.reload("/");
  }, [isUpdated]);

  useEffect(() => {
    if (error) {
      toast.error(error.error);

      dispatch(searchClientByEmailResetError());

    }
  }, [dispatch, error]);

  const [isOpen, setisOpen] = useState(false);
  const [currentUser, setcurrentUser] = useState([]);
  const [AdCard, setAdCard] = useState(false);

  function cardOpen(item) {
    if (!item) {
      console.error("item is undefined");
      return;
    }

    const user = clients.find((client) => client.email === item.email);

    setcurrentUser(user);
    setisOpen(true);
  }

  function cardClose(e, item) {
    setcurrentUser(item);
    setisOpen(false);
  }

  const toggle = () => {
    setisOpen(!isOpen);
  };

  const handleDisableClient = (uid, status) => {
    dispatch(disableClient(uid, status));
  };

  const handleViewAds = (clientId) => {
    
    dispatch(getTotalAds(clientId));
    setAdCard(true);
  };

  const columns = [
    {
      field: "details",
      headerName: "Details",
      width: 100,
      renderCell: (params) => (
        <div>
          <button
            onClick={() => cardOpen(params.row)}
            style={{ cursor: "pointer", marginRight: "10px", color: "blue" }}
          >
            <FaEye size={17} />

          </button>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <div>
          <button
            onClick={() =>
              handleDisableClient(params.row.id, !params.row.isDisabled)
            }
            style={{
              color: params.row.isDisabled ? "green" : "red",
              cursor: "pointer",
              border: "none",
              background: "transparent",
            }}
          >
            {params.row.isDisabled ? "Activate" : "Suspend"}
          </button>
        </div>
      ),
    },
    { field: "name", headerName: "Client Name", width: 190 },
    { field: "email", headerName: "Client Email", width: 250 },
    { field: "city", headerName: "Client City", width: 100 },
    {
      field: "totalAds",
      headerName: "Total Ads",
      width: 90,
      renderCell: (params) => (
        <button
          onClick={() => handleViewAds(params.row.uid)}
          style={{ cursor: "pointer", color: "blue" }}
        >
          <FaEye size={17} />

        </button>
      ),
    },
  ];

  const rows = clients.map((client) => ({
    id: client.uid,
    uid:client._id,
    name: client.name,
    email: client.email,
    city: client.city,
    isDisabled: client.isDisabled,
    totalAds: client.totalAds,
  }));

  return (
    <div className={`w-[100vw] h-auto mt-4`}>
      <hr />

      {isOpen && (
        <Carddummy
          city={currentUser.city}
          area={currentUser.area}
          lat={currentUser.lat}
          long={currentUser.long}
          fullAddress={currentUser.fullAddress}
          points={currentUser.totalPoints}
          Ads={currentUser.totalAds}
          time={currentUser.registerationTime}
          type={currentUser.type}
          num={currentUser.number}
          followings={currentUser.following && currentUser.following.length}
          name={currentUser.name}
          email={currentUser.email}
          fav={currentUser.favourites && currentUser.favourites.length}
          img={currentUser.imageUrl}
          cardClose={cardClose}
          cardOpen={cardOpen}
          toggle={toggle}
        />
      )}

      {AdCard && <Card3 totalAds={totalAds} setAdCard={setAdCard} />}

      <Paper sx={{ height: 500, width: "70vw", margin: "auto", marginLeft: "0px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          sx={{
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#f5f5f5",
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default Clients;
