import React from "react";
import { CloudinaryContext } from "cloudinary-react";
import { Cloudinary } from "cloudinary-core";
import { uploadFirstimage } from "../Redux/ImageUpload";
import { useDispatch } from "react-redux";

const ImageUpload1 = () => {
  const dispatch = useDispatch();

  const handleUpload = async (event) => {
    const cloudinary = new Cloudinary({ cloud_name: "di9vpom9l" });

    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("folder", "mobilemate"); 
    formData.append("upload_preset", "my_unsigned_upload"); // Use your actual upload preset

    dispatch(uploadFirstimage(formData));
  };

  return (
    <CloudinaryContext cloudName="di9vpom9l">
      <div>
        <input className="text-[20px] p-2 " type="file" accept="image/*" onChange={handleUpload} />
      </div>
    </CloudinaryContext>
  );
};

export default ImageUpload1;
