// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const AllMobilesReducer = createSlice({
  //STATE NAME
  name: "allMobiles",

  //STATE INITIAL VALUE
  initialState: {
    isUpdated: false,
    url:"",
    mobiles: [],
    filteredPhones: [],
    status: "idle",
    error: null,
  },

  //REDUCER FUNCTIONS
  reducers: {
    allMobilesRequest(state) {
      state.status = "loading";
    },
    allMobilesSuccess(state, action) {
      state.status = "succeeded";
      state.mobiles = action.payload.phones;
    },

    allMobilesFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
    filterAllMobilesRequest(state) {
      state.status = "loading";
    },
    filterAllMobilesSuccess(state, action) {
      state.status = "succeeded";
      state.filteredPhones = action.payload.phones;
    },

    filterAllMobilesFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },

    deleteSingleMobileRequest(state) {
      state.status = "loading";
    },
    deleteSingleMobileSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.isUpdated = true;
    },

    deleteSingleMobileFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },

  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
  allMobilesRequest,
  allMobilesSuccess,
  allMobilesFailure,
  filterAllMobilesFailure,
  filterAllMobilesSuccess,
  filterAllMobilesRequest,
  deleteSingleMobileRequest,
  deleteSingleMobileSuccess,
  deleteSingleMobileFailure,
  sendURLRequest,
  sendURLSuccess,
  sendURLFailure
} = AllMobilesReducer.actions;

//EXPORT REDUCER

export default AllMobilesReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const getAllMobiles = () => async (dispatch) => {
  dispatch(allMobilesRequest());

  try {
    const response = await fetch(
      `https://mm.magma3c.com/mobiles/latestMobiles`
    );

    const responseData = await response.json();

    dispatch(allMobilesSuccess({ phones: responseData }));
  } catch (error) {
    dispatch(allMobilesFailure(error.message));
  }
};

export const filterAllMobiles = (newMobiles) => async (dispatch) => {
  dispatch(filterAllMobilesRequest());

  try {
    dispatch(filterAllMobilesSuccess({ phones: newMobiles }));
  } catch (error) {
    dispatch(filterAllMobilesFailure(error.message));
  }
};

export const deleteSingleMobile = (id, currentUser) => async (dispatch) => {
  dispatch(deleteSingleMobileRequest());

  try {
    const response = await fetch(
      `https://mm.magma3c.com/mobiles/deleteMobile/${id}`,
      {
        method: "DELETE",
        headers: {
          authorization: JSON.stringify(currentUser),
        },
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      dispatch(deleteSingleMobileFailure(responseData.message));
    } else {
      dispatch(deleteSingleMobileSuccess({ message: responseData.message }));
    }
  } catch (error) {
    dispatch(deleteSingleMobileFailure(error.message));
  }
};


