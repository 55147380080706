import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { rejectNews } from "../news/NewsSlice";
import { useSelector, useDispatch } from "react-redux";
import { updateSingleNews } from "../../../Redux/NewsReducer";


const AllPendingNews = () => {

  const dispatch = useDispatch();

  const { pendingNews, status } = useSelector((state) => state.news);

  const { currentUser } = useSelector((state) => state.user);


  function approveOrReject(id, value) {

    dispatch(updateSingleNews(id, value));

  }
  return (
    <div>

      <h1 className="text-2xl font-semibold text-center text-gray-600">
        All pending News
      </h1>
      <div className="grid grid-cols-3 justify-between gap-4 my-3 mx-2  pb-5 text-gray-500 ">
        {pendingNews &&
          pendingNews.map((news) => (
            <div className="hover:scale-105 duration-150 h-[300px]  space-y-3 pb-4 bg-white shadow-md shadow-gray-900 ">
              <Link
                to={`/newsdetails/${news.title}/${news._id}`}
                key={news._id}
        
              >
                <div className="hover:text-gray-400 ">
                <img
                    src={news.image1}
                    alt="news images"
                    className="  object-center bg-no-repeat h-[180px] w-[400px] "
                  />
                </div>

                <div className="text-sm px-2 space-y-1 mx-3 mt-2">
                <h1 className="font-bold">BRAND : {news.brand}</h1>
                  <p className="font-semibold">{news.title}</p>
                  <p className="font-semibold  line-clamp-1">
                  {parse(news.description)}
                  </p>
                </div>
              </Link>

              {currentUser.role == "admin" && (
              <div className="flex justify-between space-x-4 px-3 text-sm ">
                  <button
                    className="w-full px-4 py-2 text-center bg-gray-800 text-white items-center  duration-300 hover:scale-105 "
                    onClick={() => approveOrReject(news._id, "successful")}
                  >
                    Approve
                  </button>
                  <button
                    
                    className="w-full bg-gray-800 text-white px-4 py-2 text-center items-center duration-300 hover:scale-105"
                    onClick={() => approveOrReject(news._id, "Rejected")}
                  >
                    Reject
                  </button>
         
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default AllPendingNews;


