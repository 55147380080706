import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import MenuSideBar from "../MainDashboard/MenuSideBar/MenuSideBar";
import LogIn from "../MainDashboard/LoginSignUp/LogIn";
import Logout from "../MainDashboard/LoginSignUp/Logout";

const Layout = ({
  toggleSidebar,
  sidebarOpen,
  setSidebarOpen,
  toggleLogout,
}) => {
  const { token } = useSelector((state) => state.user);

  const [logoutpageVisible, setLogoutPageVisible] = useState(false);

  const [logInpageVisible, setLogInPageVisible] = useState(true);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();

  const handleLogInPageVisibilty = () => {
    setLogInPageVisible(true);
  };

  const handleLogoutPageVisibilty = () => {
    setLogoutPageVisible(!logoutpageVisible);
  };

  const handleAuthentication = () => {
    setIsAuthenticated(true);
    navigate("/");
  };

  return (
    <div className="container-fluid gx-0">
      
      {token ? (
        <div className="flex bg-gray-100 overflow-x-hidden h-screen">
          {sidebarOpen ? (
            <div className="fixed z-20 w-56 bg-white h-full lg:hidden shadow-md">
              <MenuSideBar
                setSidebarOpen={setSidebarOpen}
                handleLogout={handleLogoutPageVisibilty}
              />
            </div>
          ) : null}

          <Sidebar handleLogoutPageVisibilty={handleLogoutPageVisibilty} />

          <div className="lg:ml-[22%] text-[13px] 2xl:text-[1.5rem] lg:text-[1rem]  bg-gray-100  lg:w-[78%]">
            <div
              className={`fixed px-2  top-0 z-10 bg-gray-100 right-0 left-[23%]  transition-all duration-300  `}
            >
              <Navbar toggleSidebar={toggleSidebar} />
            </div>
            <div className=" mt-16 container ml-3 pr-4">
              
              {logoutpageVisible ? (
                <Logout handleLogoutPageVisibilty={handleLogoutPageVisibilty} />
              ) : (
                <Outlet />
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          {logInpageVisible && (
            <LogIn
              handleLogInPageVisibilty={handleLogInPageVisibilty}
              handleAuthentication={handleAuthentication}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Layout;
