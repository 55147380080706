import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { delAds, getClientsAds, changeStatus } from "../../../Redux/BuyandSell";
import { IoMdEye } from "react-icons/io";

import Card2 from "../Card2";
import { Bar } from "react-chartjs-2";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { mobilesPerBrand } from "../../../Redux/logsReducer";

const Ads = () => {
  const dispatch = useDispatch();
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteAdId, setDeleteAdId] = useState("");
  const { clientsAds } = useSelector((state) => state.buyAndSell);
  const [filteredRows, setFilteredRows] = useState([]);
  useEffect(() => {
    if (clientsAds.length > 0) countBrands();
  }, [clientsAds]);

  useEffect(() => {
    if (clientsAds?.length > 0) {
      const formattedRows = clientsAds.map((ads, index) => ({
        ad: ads,
        id: ads._id,
        // _id: mobile.model.replace(/ /g, "-"),
        title: ads.title || "N/A",
        brand: ads.brand || "N/A",
        email: ads.uid.email || "N/A",
        status:
          ads.status === 1
            ? "Active"
            : ads.status === 0
            ? "InActive"
            : ads.status === 2
            ? "Expire"
            : ads.status === 3
            ? "Sold"
            : "Rejected",
        price: ads.price || "N/A",
      }));
      setFilteredRows(formattedRows); // Initialize filteredRows
    }
  }, [clientsAds]);
  useEffect(() => {
    if (!clientsAds || clientsAds.length === 0) {
      dispatch(getClientsAds());
    }
  }, [clientsAds, dispatch]);

  function deleteADs(id) {
    window.confirm("Are You Sure You Want To Delete This Ad?") &&
      dispatch(delAds(id));

    const newItems = current.filter((item) => item._id !== id);

    setcurrent(newItems);
  }
  const handleDeleteAd = () => {
    deleteADs(deleteAdId);
    setDeletePopup(false);
  };

  const [currentAds, setcurrentAds] = useState({});
  const [isOpen, setisOpen] = useState(false);

  const [current, setcurrent] = useState([]);

  const modalRef = useRef(null);

  const columns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "Ad_Detail",
      headerName: "AD Details ",
      width: 130,
      renderCell: (params) => (
        <span
          onClick={(e) => cardOpen(e, params.row.ad)}
          className="cursor-pointer"
        >
          <IoMdEye
            style={{ marginTop: "10px" }}
            color="#000"
            size={24}
            className="ml-4"
          />
        </span>
      ),
    },
    { field: "title", headerName: "AD Title", width: 150 },
    { field: "brand", headerName: "AD Brand", width: 130 },
    { field: "email", headerName: "User Email", width: 250 },
    { field: "status", headerName: "AD Status", width: 130 },
    { field: "price", headerName: "AD Price", width: 130 },
    {
      field: "delete_Ad",
      headerName: "Delete Ad",
      width: 130,
      renderCell: (params) => (
        <span
          onClick={() => {
            setDeletePopup(true);
            setDeleteAdId(params.row.id);
          }}
          className="hover:text-red-500 text-red-500 underline cursor-pointer sm:w-[21%] md:w-[21%] text-sm text-center  pr-2 pl-2 w-[21%] py-1"
        >
          Delete
        </span>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      width: 130,
      renderCell: (params) => (
                      <span
                      onClick={() => changeStatus(params.row.id, params.row.status)}
                      className=" cursor-pointer md:w-[21%] text-sm text-center sm:w-[21%] md:text-sm w-[21%] bg-transparent pr-2 pl-2"
                    >
                      <select
                        className=" bg-transparent"
                        name="filter"
                        id=""
                        onChange={(e) =>
                          dispatch(changeStatus(params.row.id, e.target.value))
                        }
                      >
                        <option value="" disabled selected>
                          EDIT
                        </option>
                        <option value="2">Expire</option>
                        <option value="4">Reject Ad</option>
                      </select>
                    </span>
      ),
    },
  ];

  //UPDATE ALL MOBILES PAGINATION

  function cardOpen(e, item) {
    setcurrentAds(item);
    setisOpen(true);
  }
  function cardClose(e, item) {
    setcurrentAds(item);
    setisOpen(false);
  }

  const toggle = () => {
    setisOpen(!isOpen);
  };

  const countBrands = () => {
    let updatedCounts = {
      TEL: 0,
      SAMSUNG: 0,
      INFINIX: 0,
      ONEPLUS: 0,
      APPLE: 0,
      MOTOROLA: 0,
      HTC: 0,
      OPPO: 0,
      XIAOMI: 0,
      TECNO: 0,
      HUAWEI: 0,
      VIVO: 0,
      LENOVO: 0,
      GOOGLE: 0,
      NOKIA: 0,
      SPARX: 0,
      REALME: 0,
      POCO: 0,
      QMOBILE: 0,
      SONY: 0,
      LG: 0,
      ZTE: 0,
      GIONEE: 0,
      TOSHIBA: 0,
      PANASONIC: 0,
      ALCATEL: 0,
      BLACKBERRY: 0,
    };

    clientsAds.forEach((item) => {
      Object.keys(updatedCounts).forEach((brand) => {
        if (item.brand === brand) {
          updatedCounts[brand] += 1;
        }
      });
    });
    dispatch(mobilesPerBrand(updatedCounts));
  };

  const { brandsMobilesCount } = useSelector((state) => state.logs);

  // Define an array of colors for each bar
  const barColors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#FF9F40",
    "#9966FF",
    "#FF3366",
    "#FF33CC",
    "#33FF57",
    "#FF5733",
    "#33B5E5",
    "#FFB74D",
    "#009688",
    "#E91E63",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#00BCD4",
    "#FF5722",
    "#2196F3",
    "#673AB7",
    "#9C27B0",
    "#FF9800",
    "#FFEB3B",
    "#607D8B",
    "#FF4081",
  ];

  // Preparing the data for the Bar chart
  const chartData = {
    labels: Object.keys(brandsMobilesCount), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
    datasets: [
      {
        label: "Mobile Ads per Brand",
        data: Object.values(brandsMobilesCount), // Counts of each brand
        backgroundColor: barColors.slice(
          0,
          Object.keys(brandsMobilesCount).length
        ), // Apply unique color to each bar
        borderColor: "#36A2EB", // Border color of the bars
        borderWidth: 1,
      },
    ],
  };

  // Chart options for better presentation
  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Mobile Ads per Brand",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Clicks: ${context.raw}`; // Format the tooltip to show the count of clicks
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Brands",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Ads Count",
        },
      },
    },
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setDeletePopup(false); // Close the modal
      }
    };

    if (deletePopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="md:w-[75vw] lg:w-[75vw] sm:w-[75vw] w-[75vw] mt-4 overflow-x-hidden h-[100vh]">
      {deletePopup && (
        <div className="overlay">
          <div className="sold__model" ref={modalRef}>
            <h1 className="text-2xl text-center">
              Do you want to delete this AD?
            </h1>
            <div className="w-100">
              <button className="yes__btn" onClick={handleDeleteAd}>
                Yes, Delete
              </button>
              <button onClick={() => setDeletePopup(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <Card2
          views_len={currentAds.views.length}
          likes_len={currentAds.likes.length}
          published={currentAds.publishedOn}
          lat={currentAds.lat}
          long={currentAds.long}
          area={currentAds.area}
          city={currentAds.city}
          add={currentAds.fullAddress}
          des={currentAds.description}
          status={currentAds.status}
          id={currentAds._id}
          img={currentAds.images[0]}
          title={currentAds.title}
          brand={currentAds.brand}
          condition={currentAds.conditionInNum}
          price={currentAds.price}
          cardOpen={cardOpen}
          cardClose={cardClose}
          toggle={toggle}
          userName={currentAds.uid.name}
          userAddress={currentAds.uid.fullAddress}
          userArea={currentAds.uid.area}
          imageUrl={currentAds.uid.imageUrl}
          email={currentAds.uid.email}
        />
      )}
      <Paper sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          sx={{ border: 0 }}
        />
      </Paper>
      <div className="p-6">
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default Ads;
