import React, { useState,useEffect } from "react";
import GlossaryForm from "./GlossaryForm";
import { useSelector } from "react-redux";
import Glossary from "./Glossarys";


const GlossaryContainer = () => {

  const [isFormVisible, setFormVisible] = useState(false);

  const { isUpdated } = useSelector((state) => {
    return state.glossary;
  });

  useEffect(()=>{
    if(isUpdated)
    {
       window.location.reload("/")
    }
  },[isUpdated])

  const handleFormVisible = () => {
    setFormVisible(!isFormVisible);
  };

  return (
    <div>
      {isFormVisible ? (
        <GlossaryForm handleFormVisible={handleFormVisible} />
      ) : (
        <Glossary handleFormVisible={handleFormVisible}/>
       
      )}
    </div>
  );
};

export default GlossaryContainer;
