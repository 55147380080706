import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserReports } from "../../../Redux/ReportsReducers";
import { disableUser } from "../../../Redux/ReportsReducers";
import { rejectUser } from "../../../Redux/ReportsReducers";
import { previewUser } from "../../../Redux/ReportsReducers";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { GrClose } from "react-icons/gr";
import Paper from "@mui/material/Paper";
import CardDummy2 from "../../CardDummy2";

export default function CustomizedTables() {
  const dispatch = useDispatch();
  const { userReports, isUpdate, status, previewUserReport } = useSelector(
    (state) => state.reports
  );
  const [IsOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!IsOpen);

  // Fetch user reports when component mounts
  useEffect(() => {
    dispatch(UserReports());
  }, [dispatch]);

  // Refresh the page if reports were updated
  useEffect(() => {
    if (isUpdate) window.location.reload("/");
  }, [isUpdate]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 130,
      renderCell: (params) => (
        <a
          href={params.row.url}
          className="text-blue-500"
          onClick={(e) => {
            e.preventDefault(); // Prevent default link behavior
            dispatch(previewUser(params.row.reported_id._id));
            setIsOpen(true);
          }}
        >
          {params.row.name}
        </a>
      ),
    },
    { field: "email", headerName: "Email", width: 220 },
    { field: "reason", headerName: "Reason", width: 100 },
    { field: "details", headerName: "Details", width: 220 },
    { field: "status", headerName: "Status", width: 100 },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      renderCell: (params) => {
        return (
          <button
            onClick={() => dispatch(disableUser(params.row.reported_id._id))}
            className="text-red-600 rounded font-bold border-1 hover:border-white p-2"
          >
            Disable
          </button>
        );
      },
    },
    {
      field: "reject",
      headerName: "Reject",
      width: 100,
      renderCell: (params) => {
        return (
          <GrClose
            onClick={() => dispatch(rejectUser(params.row.id))}
            className="cursor-pointer text-red-600 w-5 m-auto mt-4"
          />
        );
      },
    },
  ];

  // Filter reports by 'pending' status
  const filteredReports = userReports.filter(
    (report) => report.status === "pending"
  );

  // Prepare rows for the DataGrid
  const rows = filteredReports.map((report) => ({
    id: report._id,
    name: report.reported_id.name,
    email: report.reported_id.email,
    reason: report.reason,
    details: report.details,
    status: report.status,
    reported_id: report.reported_id,
    url: report.url,
  }));

  return (
    <>
      {IsOpen && (
        <CardDummy2
          toggle={toggle}
          name={previewUserReport.name}
          email={previewUserReport.email}
          img={previewUserReport.imageUrl}
          reports={previewUserReport.reports}
        />
      )}

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {status === "loading" ? (
          <div>Loading...</div> // Display loading state while data is being fetched
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5} // Display 5 items per page
            rowsPerPageOptions={[5]} // Allow the user to select 5 items per page
            disableSelectionOnClick
            pagination
            autoHeight
            components={{
              Toolbar: GridToolbar, // Include default filter toolbar
            }}
          />
        )}
      </Paper>
    </>
  );
}
