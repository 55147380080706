// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const Notify = createSlice({
  //STATE NAME
  name: "notify",

  //STATE INITIAL VALUE
  initialState: {
    notifications:[],
    loading: false,
    error: null
  },

  //REDUCER FUNCTIONS

  reducers: {
    notificationRequest(state) {
      state.status = "loading";
      state.message = " ";
    },

    notificationSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
    },

    notificationFailure(state, action) {
      state.status = "failed";
      state.error = action.payload.message;
  }
},extraReducers: (builder) => {
  builder
    .addCase(fetchAllNotifications.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchAllNotifications.fulfilled, (state, action) => {
      state.loading = false;
      state.notifications = action.payload.notifications;
    })
    .addCase(fetchAllNotifications.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
},
});

//EXPORT REDUCER ACTION FUNCTIONS
export const selectNotifications = (state) => state.notify.notifications;
export const { notificationRequest, notificationSuccess, notificationFailure } =
  Notify.actions;

//EXPORT REDUCER

export default Notify.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const postNotify = (notification) => async (dispatch) => {
  dispatch(notificationRequest());

  try {
    const response = await fetch(
      `https://mm.magma3c.com/chats/sendNotification`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(notification),
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
        dispatch(notificationFailure({message:responseData.message}));
    }

    dispatch(notificationSuccess({ message: responseData.message }));
  } catch (error) {
    dispatch(notificationFailure({ message: error.message }));
  }
};
export const fetchAllNotifications = createAsyncThunk(
  "notify/fetchAllNotifications",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        "https://mm.magma3c.com/chats/fetchNotifications"
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)
