// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const ReportsReducers = createSlice({
  //STATE NAME
  name: "reports",

  //STATE INITIAL VALUE
  initialState: {
    message: "",
    status: "idle",
    error: null,
    isUpdate:false,
    userReports: [],
    AdReports:[],
    previewUserReport: {},
  },

  //REDUCER FUNCTIONS
  reducers: {
    UserReportsRequest(state) {
      state.status = "loading";
    },
    UserReportsSuccess(state, action) {
      state.status = "succeeded";
      state.userReports = action.payload.userReports;
     
    },
    AdReportsRequest(state) {
      state.status = "loading";
    },
    AdReportsSuccess(state, action) {
      state.status = "succeeded";
      state.AdReports = action.payload.AdReports;
    
    },
    previewUserRequest(state) {
      state.status = "loading";
    },
    previewUserSuccess(state, action) {
      state.status = "succeeded";
      state.previewUserReport = action.payload.previewUserReport;
    },
    Failure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
    disableUserRequest(state, action) {
      state.status = "loading";
    },
    disableUserSuccess(state, action) {
      state.status = "succeed";
      state.isUpdate= true;
    },
    disableUserFailure(state, action) {
      state.status = "failure";
    },
    disableAdRequest(state, action) {
      state.status = "loading";
    },
    disableAdSuccess(state, action) {
      state.status = "succeed";
      state.isUpdate= true;

    },
    disableAdFailure(state, action) {
      state.status = "failure";
    },
    rejectUserRequest(state, action) {
      state.status = "loading";
    },
    rejectUserSuccess(state, action) {
      state.status = "succeed";
      state.isUpdate= true;
    },
    rejectUserFailure(state, action) {
      state.status = "failure";
    },
    rejectAdRequest(state, action) {
      state.status = "loading";
    },
    rejectAdSuccess(state, action) {
      state.status = "succeed";
      state.isUpdate= true;
    },
    rejectAdFailure(state, action) {
      state.status = "failure";
    },
    disableClientFailure(state, action) {
      state.status = "failure";
    },
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
  previewUserRequest,
  previewUserSuccess,
  AdReportsRequest,
  AdReportsSuccess,
  UserReportsRequest,
  UserReportsSuccess,
  disableUserRequest,
  disableUserSuccess,
  disableUserFailure,
  disableAdRequest,
  disableAdSuccess,
  disableAdFailure,
  rejectUserRequest,
  rejectUserSuccess,
  rejectUserFailure,
  rejectAdRequest,
  rejectAdSuccess,
  rejectAdFailure,
  Failure,
} = ReportsReducers.actions;

//EXPORT REDUCER

export default ReportsReducers.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const UserReports = () => async (dispatch) => {
  dispatch(UserReportsRequest());

  try {
    const response = await fetch(
      `https://mm.magma3c.com/report/allUserReports`
    );

    const responseData = await response.json();

    dispatch(UserReportsSuccess({ userReports: responseData }));
  } catch (error) {
    dispatch(Failure(error.message));
  }
};
export const getAdReports = () => async (dispatch) => {
  dispatch(AdReportsRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/report/allAdReports`);

    const responseData = await response.json();

    dispatch(AdReportsSuccess({ AdReports: responseData }));
  } catch (error) {
    dispatch(Failure(error.message));
  }
};
export const previewUser = (id) => async (dispatch) => {
  
  dispatch(previewUserRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/users/user/${id}`);

    const responseData = await response.json();

    dispatch(previewUserSuccess({ previewUserReport: responseData }));
  } catch (error) {
    dispatch(Failure(error.message));
  }
};


export const disableUser = (id) => async (dispatch) => {


  dispatch(disableUserRequest());

  

  const URL = `https://mm.magma3c.com/users/disabledUserById`;

  try {
    if (!id) {
      throw new Error("Invalid UID");
    }

    const data = await fetch(URL, {
      method: "PATCH",
      body: JSON.stringify({ id ,bool:true}),
      headers: { "Content-Type": "application/json" },
    });

    if (!data.ok) {
      const errorResponse = await data.json();
      dispatch(disableUserFailure({ message: errorResponse.message || "Failed to disable client" }));
      return;
    }

    const response = await data.json();
    dispatch(disableUserSuccess({ message: response.message || "Client disabled successfully" }));
  } catch (error) {
    console.error("Error disabling client:", error);
    dispatch(disableUserFailure({ message: error.message || "An error occurred" }));
  }
}
export const rejectUser = (id) => async (dispatch) => {



  dispatch(rejectUserRequest()); 

  

  const URL = `https://mm.magma3c.com/report/updateReport`;

  try {
    if (!id) {
      throw new Error("Invalid UID");
    }

    const data = await fetch(URL, {
      method: "PATCH",
      body: JSON.stringify({ id}),
      headers: { "Content-Type": "application/json" },
    });

    if (!data.ok) {
      const errorResponse = await data.json();
      dispatch(rejectUserFailure({ message: errorResponse.message || "Failed to reject client" }));
      return;
    }

    const response = await data.json();
    dispatch(rejectUserSuccess({ message: response.message || "Client rejected successfully" }));
  } catch (error) {
    console.error("Error disabling client:", error);
    dispatch(rejectUserFailure({ message: error.message || "An error occurred" }));
  }
}
export const rejectAd = (id) => async (dispatch) => {



  dispatch(rejectAdRequest()); 

  

  const URL = `https://mm.magma3c.com/report/updateReport`;

  try {
    if (!id) {
      throw new Error("Invalid UID");
    }

    const data = await fetch(URL, {
      method: "PATCH",
      body: JSON.stringify({ id}),
      headers: { "Content-Type": "application/json" },
    });

    if (!data.ok) {
      const errorResponse = await data.json();
      dispatch(rejectAdFailure({ message: errorResponse.message || "Failed to reject client" }));
      return;
    }

    const response = await data.json();
    dispatch(rejectAdSuccess({ message: response.message || "Client rejected successfully" }));
  } catch (error) {
    console.error("Error disabling client:", error);
    dispatch(rejectAdFailure({ message: error.message || "An error occurred" }));
  }
}

export const disableAd = (id,reportId) => async (dispatch) => {

  dispatch(disableAdRequest());

 

  const URL2 = `https://mm.magma3c.com/report/expireAd`;

  try {
    if (!id) {
      throw new Error("Invalid UID");
    }

  

    const data2 = await fetch(URL2, {
      method: "PATCH",
      body: JSON.stringify({ id}),
      headers: { "Content-Type": "application/json" },
    });

    if (!data2.ok) {
      const errorResponse = await data2.json();
      dispatch(disableAdFailure({ message: errorResponse.message || "Failed to reject client" }));
      return;
    }

    const response = await data2.json();
    dispatch(disableAdSuccess({ message: response.message || "Client rejected successfully" }));
  } catch (error) {
    console.error("Error disabling client:", error);
    dispatch(disableAdFailure({ message: error.message || "An error occurred" }));
  }
}
