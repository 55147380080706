import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ImageUpload1 from "../../ImageUpload1";
import { postAd, reset } from "../../../Redux/AdsReducer";
import { toast } from "react-toastify";
import { DayPicker } from "react-day-picker";
import { format, isWithinInterval, parse } from "date-fns";
import "react-day-picker/dist/style.css";

// UNCOMMENT THIS CODE IF USE WANT TO GET ACTIVE ADS BY USING SOCKET.IO

// import { io } from "socket.io-client";

// const socket = io("https://mm.magma3c.com/", {
//   transports: ["websocket"], // Forces WebSocket transport (can also try with ["websocket", "polling"])
//   // If needed, add headers or credentials here
// });

// // Listen for the "activeAds" event from the server
// socket.on("welcome", (data) => {
//   console.log(data);
//   // You can update your UI with the active ads data here
// });

// // Handle connection error (useful for debugging)
// socket.on("connect_error", (err) => {
//   console.error("Connection error:", err.message); // Log error message
// });

// // Handle disconnection event
// socket.on("disconnect", (reason) => {
//   console.log("Disconnected:", reason); // Optional: log the reason for disconnection
// });

export default function AdsDetails() {
  const { message, isUploaded } = useSelector((state) => state.ads);

  const dispatch = useDispatch();

  const { imageUrl1 } = useSelector((state) => state.imageUplaod);

  const [page, setPage] = useState("");

  const [direction, setDirection] = useState("");

  const [activeAds, setActiveAds] = useState("");

  const [url, setUrl] = useState("");

  // USESTATES

  const [HOMETOPscheduledDates, setHOMETOPScheduledDates] = useState([]);
  const [HOMEBOTTOMscheduledDates, setHOMEBOTTOMScheduledDates] = useState([]);
  const [HOMELEFTscheduledDates, setHOMELEFTScheduledDates] = useState([]);
  const [HOMERIGHTscheduledDates, setHOMERIGHTScheduledDates] = useState([]);

  const [NEWSTOPscheduledDates, setNEWSTOPScheduledDates] = useState([]);
  const [NEWSBOTTOMscheduledDates, setNEWSBOTTOMScheduledDates] = useState([]);
  const [NEWSLEFTscheduledDates, setNEWSLEFTScheduledDates] = useState([]);
  const [NEWSRIGHTscheduledDates, setNEWSRIGHTScheduledDates] = useState([]);

  const [REVIEWSTOPscheduledDates, setREVIEWSTOPScheduledDates] = useState([]);
  const [REVIEWSBOTTOMscheduledDates, setREVIEWSBOTTOMScheduledDates] =
    useState([]);
  const [REVIEWSLEFTscheduledDates, setREVIEWSLEFTScheduledDates] = useState(
    []
  );
  const [REVIEWSRIGHTscheduledDates, setREVIEWSRIGHTScheduledDates] = useState(
    []
  );

  const [MOBILESTOPscheduledDates, setMOBILESTOPScheduledDates] = useState([]);
  const [MOBILESBOTTOMscheduledDates, setMOBILESBOTTOMScheduledDates] =
    useState([]);
  const [MOBILESLEFTscheduledDates, setMOBILESLEFTScheduledDates] = useState(
    []
  );
  const [MOBILESRIGHTscheduledDates, setMOBILESRIGHTScheduledDates] = useState(
    []
  );

  const [selectedCreatedAt, setSelectedCreatedAt] = useState(null);
  const [selectedExpiresAt, setSelectedExpiresAt] = useState(null);

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const [display1, setdisplay1] = useState(false);

  const [display2, setdisplay2] = useState(false);

  const [display3, setdisplay3] = useState(false);

  const [display4, setdisplay4] = useState(false);

  const [display5, setdisplay5] = useState(false);

  const [display6, setdisplay6] = useState(false);

  const [display7, setdisplay7] = useState(false);

  const [display8, setdisplay8] = useState(false);

  const [display9, setdisplay9] = useState(false);

  const [display10, setdisplay10] = useState(false);

  const [display11, setdisplay11] = useState(false);

  const [display12, setdisplay12] = useState(false);

  const [display13, setdisplay13] = useState(false);

  const [display14, setdisplay14] = useState(false);

  const [display15, setdisplay15] = useState(false);

  const [display16, setdisplay16] = useState(false);

  const [display17, setdisplay17] = useState(false);

  const [display18, setdisplay18] = useState(false);

  const [display19, setdisplay19] = useState(false);

  const [display20, setdisplay20] = useState(false);

  // UseEffect to listen to socket events when the component mounts

  // UNCOMMENT THIS CODE IF YOU WANT TO GET ACTIVE ADS BY USING SOCKET.IO

  // useEffect(() => {
  //   // Listen for the "activeAds" event from the server and update state
  //   socket.on("activeAds", (data) => {
  //     console.log("Received active ads:", data);

  //     setActiveAds(data); // Update the state with the active ads data
  //   });

  //   // Cleanup the socket listener when the component unmounts
  //   return () => {
  //     socket.off("activeAds"); // Stop listening for activeAds when the component is unmounted
  //   };
  // }, []);

  // Fetch scheduled dates from the backend

  useEffect(() => {
    const fetchScheduledDates = async () => {
      try {
        const response = await fetch(
          "https://mm.magma3c.com/adminAds/scheduledAds"
        ); // Adjust this URL based on your API
        const ads = await response.json();

        const scheduledREVIEWSRIGHT = ads.scheduledREVIEWSRIGHT;

        const scheduledREVIEWSLEFT = ads.scheduledREVIEWSLEFT;

        const scheduledREVIEWSBOTTOM = ads.scheduledREVIEWSBOTTOM;

        const scheduledREVIEWSTOP = ads.scheduledREVIEWSTOP;

        const scheduledNEWSRIGHT = ads.scheduledNEWSRIGHT;

        const scheduledNEWSLEFT = ads.scheduledNEWSLEFT;

        const scheduledNEWSBOTTOM = ads.scheduledNEWSBOTTOM;

        const scheduledNEWSTOP = ads.scheduledNEWSTOP;

        const scheduledHOMERIGHT = ads.scheduledHOMERIGHT;

        const scheduledHOMELEFT = ads.scheduledHOMELEFT;

        const scheduledHOMEBOTTOM = ads.scheduledHOMEBOTTOM;

        const scheduledHOMETOP = ads.scheduledHOMETOP;

        const scheduledMOBILESRIGHT = ads.scheduledMOBILESRIGHT;

        const scheduledMOBILESLEFT = ads.scheduledMOBILESLEFT;

        const scheduledMOBILESBOTTOM = ads.scheduledMOBILESBOTTOM;

        const scheduledMOBILESTOP = ads.scheduledMOBILESTOP;

        // REVIEWS

        // Extract start and end datetime from the API response
        const scheduledREVIEWSRIGHTdates = scheduledREVIEWSRIGHT.map(
          (item) => ({
            createdAt: new Date(item.createdAt),
            expiresAt: new Date(item.expiresAt),
          })
        );

        setREVIEWSRIGHTScheduledDates(scheduledREVIEWSRIGHTdates);

        const scheduledREVIEWSLEFTdates = scheduledREVIEWSLEFT.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setREVIEWSLEFTScheduledDates(scheduledREVIEWSLEFTdates);

        const scheduledREVIEWSBOTTOMdates = scheduledREVIEWSBOTTOM.map(
          (item) => ({
            createdAt: new Date(item.createdAt),
            expiresAt: new Date(item.expiresAt),
          })
        );

        setREVIEWSBOTTOMScheduledDates(scheduledREVIEWSBOTTOMdates);

        const scheduledREVIEWSTOPdates = scheduledREVIEWSTOP.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setREVIEWSTOPScheduledDates(scheduledREVIEWSTOPdates);

        // NEWS

        const scheduledNEWSRIGHTdates = scheduledNEWSRIGHT.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setNEWSRIGHTScheduledDates(scheduledNEWSRIGHTdates);

        const scheduledNEWSLEFTdates = scheduledNEWSLEFT.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setNEWSLEFTScheduledDates(scheduledNEWSLEFTdates);

        const scheduledNEWSBOTTOMdates = scheduledNEWSBOTTOM.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setNEWSBOTTOMScheduledDates(scheduledNEWSBOTTOMdates);

        const scheduledNEWSTOPdates = scheduledNEWSTOP.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setNEWSTOPScheduledDates(scheduledNEWSTOPdates);

        // MOBILES

        const scheduledMOBILESRIGHTdates = scheduledMOBILESRIGHT.map(
          (item) => ({
            createdAt: new Date(item.createdAt),
            expiresAt: new Date(item.expiresAt),
          })
        );

        setMOBILESRIGHTScheduledDates(scheduledMOBILESRIGHTdates);

        const scheduledMOBILESLEFTdates = scheduledMOBILESLEFT.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setMOBILESLEFTScheduledDates(scheduledMOBILESLEFTdates);

        const scheduledMOBILESBOTTOMdates = scheduledMOBILESBOTTOM.map(
          (item) => ({
            createdAt: new Date(item.createdAt),
            expiresAt: new Date(item.expiresAt),
          })
        );

        setMOBILESBOTTOMScheduledDates(scheduledMOBILESBOTTOMdates);

        const scheduledMOBILESTOPdates = scheduledMOBILESTOP.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setMOBILESTOPScheduledDates(scheduledMOBILESTOPdates);

        // HOME

        const scheduledHOMERIGHTdates = scheduledHOMERIGHT.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setHOMERIGHTScheduledDates(scheduledHOMERIGHTdates);

        const scheduledHOMELEFTdates = scheduledHOMELEFT.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setHOMELEFTScheduledDates(scheduledHOMELEFTdates);

        const scheduledHOMEBOTTOMdates = scheduledHOMEBOTTOM.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setHOMEBOTTOMScheduledDates(scheduledHOMEBOTTOMdates);

        const scheduledHOMETOPdates = scheduledHOMETOP.map((item) => ({
          createdAt: new Date(item.createdAt),
          expiresAt: new Date(item.expiresAt),
        }));

        setHOMETOPScheduledDates(scheduledHOMETOPdates);
      } catch (error) {
        console.error("Error fetching scheduled ads:", error);
      }
    };

    fetchScheduledDates();
  }, []);

  // Function to check if a date is already scheduled (comparison with "YYYY-MM-DD" format)

  const isDateDisabled1 = (date) => {
    return HOMETOPscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled2 = (date) => {
    return HOMEBOTTOMscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled3 = (date) => {
    return HOMELEFTscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled4 = (date) => {
    return HOMERIGHTscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled5 = (date) => {
    return NEWSTOPscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled6 = (date) => {
    return NEWSBOTTOMscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled7 = (date) => {
    return NEWSLEFTscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled8 = (date) => {
    return NEWSRIGHTscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled9 = (date) => {
    return REVIEWSTOPscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled10 = (date) => {
    return REVIEWSBOTTOMscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled11 = (date) => {
    return REVIEWSLEFTscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled12 = (date) => {
    return REVIEWSRIGHTscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled13 = (date) => {
    return MOBILESTOPscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled14 = (date) => {
    return MOBILESBOTTOMscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled15 = (date) => {
    return MOBILESLEFTscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  const isDateDisabled16 = (date) => {
    return MOBILESRIGHTscheduledDates.some((scheduledDate) =>
      isWithinInterval(date, {
        start: scheduledDate.createdAt,
        end: scheduledDate.expiresAt,
      })
    );
  };

  useEffect(() => {
    if (isUploaded) {
      toast.success(message);
      dispatch(reset());
    }
  }, [message]);

  function submitHandler(e, num, Page, Direction) {

    e.preventDefault();

    const data = {
      page: Page,
      direction: Direction,
      adContent: imageUrl1,
      url,
      createdAt: selectedCreatedAt,
      expiresAt: selectedExpiresAt,
    };

    dispatch(postAd(data));

    dispatch(reset());

    setUrl("");
    setIsOpen1(false);
    setIsOpen2(false);

    switch (num) {
      case 1:
        setdisplay1(false);
        break;

      case 2:
        setdisplay2(false);
        break;

      case 3:
        setdisplay3(false);
        break;

      case 4:
        setdisplay4(false);
        break;

      case 5:
        setdisplay5(false);
        break;

      case 6:
        setdisplay6(false);
        break;

      case 7:
        setdisplay7(false);
        break;

      case 8:
        setdisplay8(false);
        break;

      case 9:
        setdisplay9(false);
        break;

      case 10:
        setdisplay10(false);
        break;

      case 11:
        setdisplay11(false);
        break;

      case 12:
        setdisplay12(false);
        break;

      case 13:
        setdisplay13(false);
        break;

      case 14:
        setdisplay14(false);
        break;

      case 15:
        setdisplay15(false);
        break;

      case 16:
        setdisplay16(false);
        break;
      default:
        console.log("No number was sent");
    }
    window.location.href("/");
  }

  const openForm1 = () => {
    setdisplay1(true);
  };
  const closeForm1 = () => {
    setdisplay1(false);
  };

  const openForm2 = () => {
    setdisplay2(true);
  };
  const closeForm2 = () => {
    setdisplay2(false);
  };

  const openForm3 = () => {
    setdisplay3(true);
  };
  const closeForm3 = () => {
    setdisplay3(false);
  };

  const openForm4 = () => {
    setdisplay4(true);
  };
  const closeForm4 = () => {
    setdisplay4(false);
  };

  const openForm5 = () => {
    setdisplay5(true);
  };
  const closeForm5 = () => {
    setdisplay5(false);
  };

  const openForm6 = () => {
    setdisplay6(true);
  };
  const closeForm6 = () => {
    setdisplay6(false);
  };

  const openForm7 = () => {
    setdisplay7(true);
  };
  const closeForm7 = () => {
    setdisplay7(false);
  };

  const openForm8 = () => {
    setdisplay8(true);
  };
  const closeForm8 = () => {
    setdisplay8(false);
  };

  const openForm9 = () => {
    setdisplay9(true);
  };
  const closeForm9 = () => {
    setdisplay9(false);
  };

  const openForm10 = () => {
    setdisplay10(true);
  };
  const closeForm10 = () => {
    setdisplay10(false);
  };

  const openForm11 = () => {
    setdisplay11(true);
  };
  const closeForm11 = () => {
    setdisplay11(false);
  };

  const openForm12 = () => {
    setdisplay12(true);
  };
  const closeForm12 = () => {
    setdisplay12(false);
  };

  const openForm13 = () => {
    setdisplay13(true);
  };
  const closeForm13 = () => {
    setdisplay13(false);
  };

  const openForm14 = () => {
    setdisplay14(true);
  };
  const closeForm14 = () => {
    setdisplay14(false);
  };

  const openForm15 = () => {
    setdisplay15(true);
  };
  const closeForm15 = () => {
    setdisplay15(false);
  };

  const openForm16 = () => {
    setdisplay16(true);
  };
  const closeForm16 = () => {
    setdisplay16(false);
  };

  const openForm17 = () => {
    setdisplay17(true);
  };
  const closeForm17 = () => {
    setdisplay17(false);
  };

  const openForm18 = () => {
    setdisplay18(true);
  };
  const closeForm18 = () => {
    setdisplay18(false);
  };

  const openForm19 = () => {
    setdisplay19(true);
  };
  const closeForm19 = () => {
    setdisplay19(false);
  };

  const openForm20 = () => {
    setdisplay20(true);
  };
  const closeForm20 = () => {
    setdisplay20(false);
  };

  return (
    <>
      <div className="bg-gray-700 w-full h-52 mt-2 mb-2">
        {activeAds.NewsTopAd && (
          <a href={activeAds.NewsTopAd.url}>
            <img
              src={activeAds.NewsTopAd.adContent}
              alt="Ads"
              className="w-full h-full"
            />
          </a>
        )}
      </div>

      <div className="w-full grid grid-cols-[1fr_3fr_1fr]">
        <div className="bg-slate-800 w-full">
          {activeAds.NewsLeftAd && (
            <a href={activeAds.NewsLeftAd.url}>
              <img
                src={activeAds.NewsLeftAd.adContent}
                alt="Ads"
                className="w-full h-full"
              />
            </a>
          )}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 p-1">
          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm1}
          >
            TOP HOME PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black  hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm2}
          >
            BOTTOM HOME PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm3}
          >
            LEFT HOME PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm4}
          >
            RIGHT HOME PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm5}
          >
            TOP NEWS PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm6}
          >
            BOTTOM NEWS PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm7}
          >
            LEFT NEWS PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm8}
          >
            RIGHT NEWS PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm9}
          >
            TOP REVIEWS PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm10}
          >
            BOTTOM REVIEWS PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm11}
          >
            LEFT REVIEWS PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm12}
          >
            RIGHT REVIEWS PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm13}
          >
            TOP MOBILES PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm14}
          >
            BOTTOM MOBILES PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm15}
          >
            LEFT MOBILES PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm16}
          >
            RIGHT MOBILES PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm17}
          >
            TOP BRAND PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm18}
          >
            BOTTOM BRAND PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm19}
          >
            LEFT BRAND PAGE
          </button>

          <button
            className="p-2 text-sm bg-gray-200 border-2 border-black hover:bg-gray-500 hover:text-white hover:shadow hover:border-none"
            onClick={openForm20}
          >
            RIGHT BRAND PAGE
          </button>
        </div>
        <div className="bg-slate-800 w-full">
          {activeAds.NewsRightAd && (
            <a href={activeAds.NewsRightAd.url}>
              <img
                src={activeAds.NewsRightAd.adContent}
                alt="Ads"
                className="w-full h-full"
              />
            </a>
          )}
        </div>
      </div>

      {display1 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm1}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 1, "Home", "Top")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Home"}
                    onChange={(e) => setPage("Home")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home">Home</option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Top"}
                    onChange={(e) => setDirection("Top")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top">Top</option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled1}
                  // hidden={false}
                  placeholder="Select Created On Date"
                />
              )}

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled1}
                  selectedDate={selectedExpiresAt}
                  // hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt  && (
              <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                Post Ad
              </button>
              )}
            </form>
          </div>
        </div>
      )}

      {display2 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm2}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 2, "Home", "Bottom")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Home"}
                    onChange={(e) => setPage("Home")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home">Home</option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Bottom"}
                    onChange={(e) => setDirection("Bottom")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom">Bottom</option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled2}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled2}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
              <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                Post Ad
              </button>
               )} 
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display3 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm3}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 3, "Home", "Left")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Home"}
                    onChange={(e) => setPage("Home")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home">Home</option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Left"}
                    onChange={(e) => setDirection("Left")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left">Left</option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled3}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled3}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display4 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm4}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 4, "Home", "Right")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Home"}
                    onChange={(e) => setPage("Home")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home">Home</option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Right"}
                    onChange={(e) => setDirection("Right")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right">Right</option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled4}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled4}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display5 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm5}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 5, "News", "Top")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"News"}
                    onChange={(e) => setPage("News")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News">News</option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Top"}
                    onChange={(e) => setDirection("Top")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top">Top</option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled5}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled5}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display6 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm6}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 6, "News", "Bottom")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"News"}
                    onChange={(e) => setPage("News")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News">News</option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Bottom"}
                    onChange={(e) => setDirection("Bottom")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom">Bottom</option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled6}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled6}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display7 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm7}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 7, "News", "Left")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"News"}
                    onChange={(e) => setPage("News")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News">News</option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Left"}
                    onChange={(e) => setDirection("Left")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left">Left</option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled7}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled7}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display8 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm8}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 8, "News", "Right")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"News"}
                    onChange={(e) => setPage("News")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News">News</option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Right"}
                    onChange={(e) => setDirection("Right")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right">Right</option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled8}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled8}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display9 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm9}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 9, "Reviews", "Top")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Reviews"}
                    onChange={(e) => setPage("Reviews")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews">Reviews</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Top"}
                    onChange={(e) => setDirection("Top")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top">Top</option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled9}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled9}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display10 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm10}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 10, "Reviews", "Bottom")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Reviews"}
                    onChange={(e) => setPage("Reviews")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews">Reviews</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Bottom"}
                    onChange={(e) => setDirection("Bottom")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom">Bottom</option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled10}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled10}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display11 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm11}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 11, "Reviews", "Left")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Reviews"}
                    onChange={(e) => setPage("Reviews")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Left"}
                    onChange={(e) => setDirection("Left")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left">Left</option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled11}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled11}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display12 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm12}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 12, "Reviews", "Right")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Reviews"}
                    onChange={(e) => setPage("Reviews")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles" disabled>
                      Mobiles
                    </option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews">Reviews</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Right"}
                    onChange={(e) => setDirection("Right")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right">Right</option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled12}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled12}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display13 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm13}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 13, "Mobiles", "Top")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Mobiles"}
                    onChange={(e) => setPage("Mobiles")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles">Mobiles</option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Top"}
                    onChange={(e) => setDirection("Top")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top">Top</option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled13}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled13}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display14 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm14}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 14, "Mobiles", "Bottom")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Mobiles"}
                    onChange={(e) => setPage("Mobiles")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles">Mobiles</option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Bottom"}
                    onChange={(e) => setDirection("Bottom")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom">Bottom</option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled14}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled14}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display15 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm15}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 15, "Mobiles", "Left")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Mobiles"}
                    onChange={(e) => setPage("Mobiles")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles">Mobiles</option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Left"}
                    onChange={(e) => setDirection("Left")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left">Left</option>
                    <option value="Right" disabled>
                      Right
                    </option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled15}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled15}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display16 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm16}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 16, "Mobiles", "Right")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Mobiles"}
                    onChange={(e) => setPage("Mobiles")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles">Mobiles</option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Right"}
                    onChange={(e) => setDirection("Right")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right">Right</option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled16}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled16}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display17 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm17}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 16, "Brands", "Right")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Brands"}
                    onChange={(e) => setPage("Brands")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles">Mobiles</option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Right"}
                    onChange={(e) => setDirection("Right")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right">Right</option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled16}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled16}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display18 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm18}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 16, "Brands", "Right")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Brands"}
                    onChange={(e) => setPage("Brands")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles">Mobiles</option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Right"}
                    onChange={(e) => setDirection("Right")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right">Right</option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled16}
                  selectedDate={selectedExpiresAt}
                  hidden={selectedCreatedAt || new Date()}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled16}
                  selectedDate={selectedCreatedAt}
                  hidden={selectedCreatedAt || new Date()}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display19 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm19}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 16, "Brands", "Right")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Brands"}
                    onChange={(e) => setPage("Brands")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles">Mobiles</option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Right"}
                    onChange={(e) => setDirection("Right")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right">Right</option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled16}
                  selectedDate={selectedExpiresAt}
                  hidden={selectedCreatedAt || new Date()}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled16}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>

            <div>
              <img
                src={activeAds.BrandTopAd && activeAds.BrandTopAd.adContent}
                alt=""
              />
            </div>
          </div>
        </div>
      )}

      {display20 && (
        <div className="inset-0 flex justify-center fixed z-10 bg-black/80 h-[100vh] w-[100vw]">
          <div className=" absolute z-20 border pt-10 m-auto font-poppins sm:text-sm sm:m-auto bg-white border-1 p-8  w-[50vw] md:w-[40vw] md:h-[auto] sm:w-[50vw] mt-5 max-h-[80vh] overflow-x-hidden overflow-y-auto">
            <p
              onClick={closeForm20}
              className="cursor-pointer top-0 relative text-gray-700 font-extrabold inline p-2 text-[21px] w-[15px] center h-[15px] mb-3"
            >
              X
            </p>
            <form
              onSubmit={(e) => submitHandler(e, 16, "Brands", "Right")}
              className="flex flex-col m-3 "
            >
              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2  text-[19px]  ">URL:</label>
                  <input
                    required
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    className="bg-transparent border-2 rounded p-1 border-gray-950 mb-3 w-[90%]"
                    type="text"
                  />
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Post Ad On Pages:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Brands"}
                    onChange={(e) => setPage("Brands")}
                  >
                    <option value="" disabled>
                      Pages
                    </option>
                    <option value="Home" disabled>
                      Home
                    </option>
                    <option value="Brands" disabled>
                      Brands
                    </option>
                    <option value="Mobiles">Mobiles</option>
                    <option value="News" disabled>
                      News
                    </option>
                    <option value="Reviews" disabled>
                      Reviews
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Section:</label>
                  <select
                    required
                    className="mb-3 w-[90%] border-2 rounded p-1 border-gray-950  "
                    value={"Right"}
                    onChange={(e) => setDirection("Right")}
                  >
                    <option value="" disabled>
                      Section
                    </option>
                    <option value="Top" disabled>
                      Top
                    </option>
                    <option value="Bottom" disabled>
                      Bottom
                    </option>
                    <option value="Left" disabled>
                      Left
                    </option>
                    <option value="Right">Right</option>
                  </select>
                </div>

                <div className="flex flex-col w-[50%]">
                  <label className="mb-2 text-[19px] ">Ad Image:</label>
                  <ImageUpload1 className="text-gray-700 rounded-sm outline-none " />
                </div>
              </div>

              <div className="flex justify-between">
                <div
                  className="flex flex-col w-[40%] bg-green-600 text-white p-2 rounded hover:bg-green-500 cursor-pointer"
                  onClick={() => setIsOpen1(!isOpen1)}
                >
                  Created On
                </div>

                <div
                  className="flex flex-col w-[40%] bg-red-600 text-white p-2 rounded hover:bg-red-500 cursor-pointer"
                  onClick={() => setIsOpen2(!isOpen2)}
                >
                  Expired On
                </div>
              </div>

              {isOpen2 && (
                <DayPicker
                  selected={selectedExpiresAt}
                  onDayClick={(data) => {
                    setSelectedExpiresAt(data);
                    setIsOpen2(false);
                  }}
                  disabled={isDateDisabled16}
                  selectedDate={selectedExpiresAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {isOpen1 && (
                <DayPicker
                  selected={selectedCreatedAt}
                  onDayClick={(data) => {
                    setSelectedCreatedAt(data);
                    setIsOpen1(false);
                  }}
                  disabled={isDateDisabled16}
                  selectedDate={selectedCreatedAt}
                  hidden={false}
                  placeholder="Select Expiry Date"
                />
              )}

              {selectedCreatedAt && selectedExpiresAt && (
                <button className="rounded pstAdd mt-5 mb-5 p-3  w-[100%]  ">
                  Post Ad
                </button>
              )}
            </form>
          </div>
        </div>
      )}

      <div className="bg-gray-700 w-full h-52 mt-2 mb-2">
        {activeAds.NewsBottomAd && (
          <a href={activeAds.NewsBottomAd.url}>
            <img
              src={activeAds.NewsBottomAd.adContent}
              alt="Ads"
              className="w-full h-full"
            />
          </a>
        )}
      </div>
    </>
  );
}
