// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const AdsReducer = createSlice({
  //STATE NAME
  name: "ads",

  //STATE INITIAL VALUE
  initialState: {
    message:" ",
    status: "idle",
    error: null,
    isUploaded:false
  },

  //REDUCER FUNCTIONS

  reducers: {

    postAdRequest(state) {
        state.status = "loading";
        state.message=" ";
      },
  
      postAdSuccess(state, action) {
        state.status = "succeeded";
        state.message = action.payload.message;
        state.isUploaded=true;
      },
  
      postAdFailure(state, action) {
        state.status = "failed";
        state.error = action.payload.error;
        state.isUploaded=true;

        // state.error = action.payload;
      },
      reset(state, action) {
        state.status = " ";
        state.isUploaded=false;
        state.error = null;
      },
   
}});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
    postAdRequest,
    postAdSuccess,
    postAdFailure,
    reset
  
} = AdsReducer.actions;

//EXPORT REDUCER

export default AdsReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const postAd = (adData) => async (dispatch) => {
  dispatch(postAdRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/adminAds/publishAdminAd`,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body:JSON.stringify(adData)
    });

    const responseData = await response.json();


    dispatch(postAdSuccess({ message: responseData.message }));
  } catch (error) {
    dispatch(postAdFailure(error.message));
  }
};
