import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTotalAds, getClientsAds } from "../Redux/BuyandSell";
import AdsCard from "./AdsCard.jsx";

const TotalAds = () => {
  const { uid } = useParams(); // Access the 'uid' parameter from the URL
  const dispatch = useDispatch();

  const [visible, setvisible] = useState(false);

  const [item, setItem] = useState({});

  const { clientsAds, status } = useSelector((state) => state.buyAndSell);

  // Fetch total ads based on 'uid'
  useEffect(() => {
    if (uid) {
      dispatch(getTotalAds(uid));
    }
  }, [uid, dispatch]);

  // Fetch clients' ads if not already loaded
  useEffect(() => {
    if (!clientsAds || clientsAds.length === 0) {
      dispatch(getClientsAds());
    }
  }, [clientsAds, dispatch]);

  const toggle = () => {
    setvisible(!visible);
  };

  return (
    <div>
      <h1 className="font-bold text-[20px]  underline">
        Total Ads :{clientsAds.length}
      </h1>
      {status === "loading" && <p>Loading...</p>}
      {clientsAds && clientsAds.length > 0 ? (
        <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 justify-items-center">
          {visible && (
            <AdsCard
              description={item.description}
              publishedOn={item.publishedOn}
              mobileNo={item.mobileNo}
              images={item.images}
              price={item.price}
              brand={item.brand}
              title={item.title}
              area={item.area}
              lat={item.lat}
              long={item.long}
              country={item.country}
              uid={item.uid}
              condition={item.condition}
              likes={item.likes}
              views={item.views}
              toggle={toggle}
            />
          )}
          {clientsAds.map((item, index) => (
            <div
              key={index}
              className="cursor-pointer p-8  border  flex flex-col items-center border-gray-400 bg-gray-50 rounded mt-5 w-[19vw]  h-[auto] m-3 "
              onClick={() => {
                setItem(item);
                setvisible(true);
              }}
            >
              {item.images && item.images.length > 0 ? (
                <img
                  className="h-[80px] w-[80px] object-contain m-auto mt-2 mb-6"
                  src={item.images[0]}
                  alt={item.title || "Image"}
                />
              ) : (
                <img
                  className="h-[80px] w-[80px] object-contain m-auto mt-2 mb-6"
                  src="/path/to/placeholder-image.jpg"
                  alt="Ad"
                />
              )}

              <p className="center text-[16px] mt-[2px] p-[3px]">
                <b>Price</b>: {item.price}
              </p>
              <p className=" center text-[16px] mt-[2px] p-[3px]">
                <b>Brand</b>: {item.brand}
              </p>
              <p className="center text-[16px] mt-[2px] p-[3px]">
                <b>Title</b>: {item.title}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p>No ads found.</p>
      )}
    </div>
  );
};

export default TotalAds;
