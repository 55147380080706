import React, { useState, useEffect } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { postNews } from "../../../Redux/NewsReducer";
import { getBrands } from "../../../Redux/BrandsReducer";
import ImageUpload1 from "../../ImageUpload1";
import ImageUpload2 from "../../ImageUpload2";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const AddNewsForm = ({
  handleNewsFormVisible,
  handleSubmitNews,
  formData,
  setFormData,
}) => {
  const { currentUser } = useSelector((state) => state.user);

  const { imageUrl1, imageUrl2, isLoading, uploaded1, uploaded2 } = useSelector(
    (state) => state.imageUplaod
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [brand, setBrand] = useState("");
  const [publisherName, setPublisherName] = useState("");
  const [images, setImages] = useState("");
  // const [image2, setImage2] = useState("");
  const [category, setCategory] = useState("");

  const { brands } = useSelector((state) => state.brands);

  const dispatch = useDispatch();

  const [mobileBrands, setMobileBrands] = useState("");

  useEffect(() => {
    if (brands.length == 0) {
      dispatch(getBrands());
    }
  }, []);

  useEffect(() => {
    setMobileBrands(brands);
  }, [brands]);

  const CATEGORY = ["REVIEWS", "NEWS"];

  function handleBand(e) {
    setBrand(e.target.value);
  }

  function handleTitle(e) {
    setTitle(e.target.value);
  }

  function handleDescription(content) {
    setDescription(content);
  }

  function handlePublisher(e) {
    setPublisherName(e.target.value);
  }

  function handleCategory(e) {
    setCategory(e.target.value);
  }

  function handleImage(e) {
    setImages(e.target.files);
  }

  function submitHandler(e) {
    e.preventDefault();

    const data = {
      title,
      description,
      brand,
      publisherName,
      category,
      uid: currentUser.uid,
      image1: imageUrl1,
      image2: imageUrl2,
    };

    dispatch(postNews(data));

    handleNewsFormVisible();
  }

  return (
    <div className="bg-white w-[700px]  mx-auto rounded-sm px-5 py-4 ">
      <button onClick={handleNewsFormVisible} className="pt-1" title="Go back">
        <IoMdArrowRoundBack />
      </button>
      <h1 className="text-center font-semibold text-2xl mb-3">
        Add News Details
      </h1>
      <form action="" className="space-y-6 text-sm " onSubmit={submitHandler}>
        <div className="flex justify-between">
          <select
            value={brand}
            onChange={handleBand}
            required
            className="bg-gray-700 text-white px-2 text-[12px]  w-full outline-none  py-3 rounded-sm"
          >
            <option value="" disabled className="gird grid-cols-4">
              Select a Brand
            </option>
            {mobileBrands &&
              mobileBrands.map((item) => {
                return <option value={item.brand}>{item.brand}</option>;
              })}
          </select>
          <select
            value={category}
            onChange={handleCategory}
            required
            className="bg-gray-700 ml-4 text-[12px]  w-full text-white px-2 outline-none  py-3 rounded-sm"
          >
            <option value="" disabled>
              Select a category
            </option>
            {CATEGORY.map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
        </div>

        <div className="mt-3">
          <label htmlFor="Select Brand" className="mb-2 font-semibold">
            Title
          </label>{" "}
          <input
            required
            value={title}
            type="text"
            name="title"
            placeholder="Please add title"
            onChange={handleTitle}
            className="w-full h-12 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />
        </div>
        <div>
          <label htmlFor="Select Brand" className="mb-2 font-semibold">
            Publisher Name
          </label>{" "}
          <input
            value={publisherName}
            type="text"
            name="title"
            placeholder="Please add Publisher Name"
            onChange={handlePublisher}
            className="w-full h-12 px-3 text-gray-700 outline-none border-[1px] border-gray-400 rounded-sm bg-white"
          />
        </div>
        <div>
          <label htmlFor="Select Brand" className="mb-2 font-semibold">
            Add Description
          </label>{" "}
          <div className="h-56">
            <ReactQuill
              required
              value={description}
              onChange={handleDescription}
              theme="snow"
              className="h-48"
            />
          </div>
        </div>
        <div className="flex justify-between mt-4">
          {" "}
          <ImageUpload1 className=" text-sm text-gray-700 outline-none  rounded-sm " />
          <ImageUpload2 className=" text-sm text-gray-700 outline-none  rounded-sm " />
        </div>
        {isLoading == "loading" ? (
          <button>Wait!!</button>
        ) : (
          <button
            type="submit"
            className="w-full h-12 mt-4 text-sm border-gray-400 rounded-sm bg-gray-800 text-white hover:bg-gray-900 duration-300"
          >
            Submit
          </button>
        )}
      </form>
    </div>
  );
};

export default AddNewsForm;
