import React from "react";
// import profile from "./images/image.png"; // Placeholder profile image

const Card2 = (props) => {
  return (
    <div
      className="fixed inset-0 z-50 bg-black/70"
      onClick={() => props.toggle()}
    >
      <div className="overflow-y-auto text-sm fixed top-[100px]   bg-gray-100 rounded z-10 w-[850px] ml-[40vh]   border border-gray-400  flex flex-col space-y-4 p-4">
        {props.cardOpen && (
          <button
            onClick={props.cardClose}
            className="p-2 m-1 mb-2 font-bold text-white bg-gray-700 rounded border-1 hover:text-red-500"
          >
            Close
          </button>
        )}

        <div className="flex justify-evenly items-start " >
          <div>
          <div className="flex flex-col  justify-between items-start ">
          {/* Check if props.img is empty */}
          {props.img === " " ? (
            <p>no img found</p>
          ) : (
            <img
              className=" object-contain rounded-lg m-auto mb-1 w-[135px] h-[100px] shadow-black shadow-md  "
              src={props.img} // Use actual image
              alt={`${props.title}`}
            />
          )}
          
        </div>
            <p className="font-bold text-center text-xl m-1">{props.title}</p>
            
            <p className="text-gray-600 m-1">
              {" "}
              <b> Brand: </b>
              {props.brand}
            </p>
           
            <p className="text-gray-600 m-1">
              {" "}
              <b> Price:</b> {props.price}
            </p>
            <p className="text-gray-600 m-1">
              {" "}
              <b> Status:</b> {props.status}
            </p>
            <p className="text-gray-600 m-1">
              {" "}
              <b> Description:</b> {props.des}
            </p>
            <p className="text-gray-600 m-1">
              {" "}
              <b> Address:</b> {props.add}
            </p>
            <p className="text-gray-600 m-1">
              {" "}
              <b> City:</b> {props.city}
            </p>
            <p className="text-gray-600 m-1">
              {" "}
              <b> Area:</b> {props.area}
            </p>
    
            <p className="text-gray-600 m-1">
              {" "}
              <b> Published:</b> {props.published}
            </p>
            <p className="text-gray-600 m-1">
              {" "}
              <b> Likes:</b> {props.likes_len}
            </p>
            <p className="text-gray-600 m-1">
              {" "}
              <b> Views:</b> {props.views_len}
            </p>
          </div>
          <div>
            <div className="">

            <div className="flex flex-col  justify-between items-start ">
          {/* Check if props.img is empty */}
          {props.imageUrl === " " ? (
            <img src={"https://cdn-icons-png.flaticon.com/512/8847/8847419.png"} className="rounded-full m-auto mb-5 w-32 shadow-black shadow-md  " alt="Profile" />
          ) : (
            <img className="rounded-full m-auto mb-5 w-32 shadow-black shadow-md  " src={props.imageUrl} alt=""  />
          )}
          
        </div>

            {/* <img className="rounded-full m-auto mb-5 w-32 shadow-black shadow-md  " src={props.imageUrl} alt=""  /> */}
            </div>
            <p className="font-bold text-center text-xl">
              {" "}
               {props.userName}
            </p>
         
            <p className="text-gray-600 m-1">
              {" "}
              <b> User Area:</b> {props.userArea}
            </p>
            <p className="text-gray-600 m-1">
              {" "}
              <b> User Email:</b> {props.email}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card2;
