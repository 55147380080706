import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  getUserLogs,
  mobilesPerBrand,
  getAdsLogs,
  getNewsLogs,
  adsPerBrand,
  singleBarDataSuccess,
} from "../../../Redux/logsReducer";

import { getAllMobiles } from "../../../Redux/AllMobiles";

import { getPublishedNews } from "../../../Redux/NewsReducer";

//Chart modules
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Registering necessary chart elements with Chart.js for both bar and line charts
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const MainDashboard = () => {
  const dispatch = useDispatch();

  const {
    userLogs,
    brandsMobilesCount,
    adsLogs,
    clientAds,
    newsLogs,
    adsPerBrandRecord,
    singleBar,
  } = useSelector((state) => state.logs);

  const { mobiles: allMobiles } = useSelector((state) => state.mobiles);

  const [mobiles, setMobiles] = useState(false);

  const [news, setNews] = useState(false);

  const [reviews, setReviews] = useState(false);

  const [ads, setAds] = useState(false);

  const [singleAd, setSingleAd] = useState(false);

  const [singleNews, setSingleNews] = useState(false);

  const [adsClicks, setAdsClicks] = useState({});

  const [newsClicks, setNewsClicks] = useState({});

  useEffect(() => {}, [singleBar]);

  // Calculate the count of mobile clicks per brand

  const countBrands = () => {
    let updatedCounts = {
      TEL: 0,
      SAMSUNG: 0,
      INFINIX: 0,
      ONEPLUS: 0,
      APPLE: 0,
      MOTOROLA: 0,
      HTC: 0,
      OPPO: 0,
      XIAOMI: 0,
      TECHNO: 0,
      HUAWEI: 0,
      VIVO: 0,
      LENOVO: 0,
      GOOGLE: 0,
      NOKIA: 0,
      SPARX: 0,
      REALME: 0,
      POCO: 0,
      QMOBILE: 0,
      SONY: 0,
      LG: 0,
      ZTE: 0,
      GIONEE: 0,
      TOSHIBA: 0,
      PANASONIC: 0,
      ALCATEL: 0,
      BLACKBERRY: 0,
    };

    userLogs.forEach((item) => {
      Object.keys(updatedCounts).forEach((brand) => {
        if (item.url.includes(brand)) {
          updatedCounts[brand] += 1;
        }
      });
    });

    dispatch(mobilesPerBrand(updatedCounts));
  };

  // Bar Chart

  useEffect(() => {
    if (userLogs.length > 0) countBrands();
  }, [userLogs]);

  useEffect(() => {
    dispatch(getUserLogs());
  }, [dispatch]);

  // Calculate the count of mobile clicks per brand
  const countBrandsForAds = () => {
    let updatedCounts = {
      TEL: 0,
      SAMSUNG: 0,
      INFINIX: 0,
      ONEPLUS: 0,
      APPLE: 0,
      MOTOROLA: 0,
      HTC: 0,
      OPPO: 0,
      XIAOMI: 0,
      TECHNO: 0,
      HUAWEI: 0,
      VIVO: 0,
      LENOVO: 0,
      GOOGLE: 0,
      NOKIA: 0,
      SPARX: 0,
      REALME: 0,
      POCO: 0,
      QMOBILE: 0,
      SONY: 0,
      LG: 0,
      ZTE: 0,
      GIONEE: 0,
      TOSHIBA: 0,
      PANASONIC: 0,
      ALCATEL: 0,
      BLACKBERRY: 0,
    };

    adsLogs.forEach((item) => {
      Object.keys(updatedCounts).forEach((brand) => {
        if (item.url.includes(brand)) {
          updatedCounts[brand] += 1;
        }
      });
    });

    dispatch(adsPerBrand(updatedCounts));
  };

  // Define an array of colors for each bar
  const barColors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#FF9F40",
    "#9966FF",
    "#FF3366",
    "#FF33CC",
    "#33FF57",
    "#FF5733",
    "#33B5E5",
    "#FFB74D",
    "#009688",
    "#E91E63",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#00BCD4",
    "#FF5722",
    "#2196F3",
    "#673AB7",
    "#9C27B0",
    "#FF9800",
    "#FFEB3B",
    "#607D8B",
    "#FF4081",
  ];

  // Preparing the data for the Bar chart
  const chartData = {
    labels: Object.keys(brandsMobilesCount), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
    datasets: [
      {
        label: "Mobile Clicks per Brand",
        data: Object.values(brandsMobilesCount), // Counts of each brand
        backgroundColor: barColors.slice(
          0,
          Object.keys(brandsMobilesCount).length
        ), // Apply unique color to each bar
        borderColor: "#36A2EB", // Border color of the bars
        borderWidth: 1,
      },
    ],
  };

  const [secondGraph, setSecondGraph] = useState(false);

  // Chart Options
  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Mobile Clicks per Brand",
      },
      tooltip: {
        callbacks: {
          label: (context) => `Clicks: ${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: { display: true, text: "Brands" },
      },
      y: {
        beginAtZero: true,
        title: { display: true, text: "Click Count" },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const clickedBrand = chartData.labels[index];
        const clickedBrandCount = chartData.datasets[0].data[index];
        handleBarClick(clickedBrand, clickedBrandCount);
      }
    },
  };

  const handleBarClick = (brand, count) => {
    const filtered = userLogs.filter((log) => log.brand === brand);

    const modelCount = {};

    // Iterate through the array and count occurrences of each model
    filtered.forEach((item) => {
      const model = item.mobileModel;
      modelCount[model] = (modelCount[model] || 0) + 1;
    });

    dispatch(singleBarDataSuccess(modelCount));
    setSecondGraph(true);
  };

  const charDataForSingleBar = {
    labels: Object.keys(singleBar), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
    datasets: [
      {
        label: "Mobile Clicks per Brand",
        data: Object.values(singleBar), // Counts of each brand
        backgroundColor: barColors.slice(0, Object.keys(singleBar).length), // Apply unique color to each bar
        borderColor: "#36A2EB", // Border color of the bars
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const optionsForSingleBar = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Mobile Clicks per Brand",
      },
      tooltip: {
        callbacks: {
          label: (context) => `Clicks: ${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: { display: true, text: "Brands" },
      },
      y: {
        beginAtZero: true,
        title: { display: true, text: "Click Count" },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const clickedBrand = chartData.labels[index];
        const clickedBrandCount = chartData.datasets[0].data[index];
        handleBarClick(clickedBrand, clickedBrandCount);
      }
    },
  };

  // Pie Chart

  const navigate = useNavigate();

  useEffect(() => {
    if (adsLogs.length > 0) countBrandsForAds();
  }, [adsLogs]);

  useEffect(() => {
    dispatch(getAdsLogs());
  }, [dispatch]);

  // Prepare the data for the Pie Chart
  const pieChartData = {
    labels: Object.keys(adsPerBrandRecord), // Brand names
    datasets: [
      {
        data: Object.values(adsPerBrandRecord), // Click counts
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#F7464A",
          "#8E44AD",
          "#2ECC71",
          "#E74C3C",
          "#3498DB",
          "#9B59B6",
          "#F39C12",
          "#D35400",
          "#1ABC9C",
          "#34495E",
          "#2C3E50",
          "#16A085",
          "#D35400",
          "#F1C40F",
          "#7F8C8D",
          "#BDC3C7",
        ], // Colors for each brand segment
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#F7464A",
          "#8E44AD",
          "#2ECC71",
          "#E74C3C",
          "#3498DB",
          "#9B59B6",
          "#F39C12",
          "#D35400",
          "#1ABC9C",
          "#34495E",
          "#2C3E50",
          "#16A085",
          "#D35400",
          "#F1C40F",
          "#7F8C8D",
          "#BDC3C7",
        ], // Hover colors
      },
    ],
  };

  // Function to handle pie chart sector clicks
  const handlePieClick = () => {
    // navigate("/logs"); // Opens the single link in a new tab
  };

  // Chart options to handle click events
  const options = {
    onClick: handlePieClick, // Set the click handler for all sectors
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.label}: ${context.raw}`; // Customize the tooltip if necessary
          },
        },
      },
    },
  };

  // Line Graph

  const [counts, setCounts] = useState([]);
  const [dates, setDates] = useState([]);

  // Fetch data from your API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://mm.magma3c.com/logs/lineGraph"); // Replace with your API URL
        const data = await response.json();

        // Assuming the backend returns { counts: [72, 100, 50], dates: ['2024-11-18', '2024-11-19', '2024-11-20'] }
        setCounts(data.counts);
        setDates(data.dates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Chart.js data for line chart
  const dataForNews = {
    labels: dates, // Dates for x-axis
    datasets: [
      {
        label: "Number Of Clicks On News", // Label for the dataset
        data: counts, // Record counts for y-axis
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        tension: 0.1, // Line curve tension (0 for sharp lines)
        borderWidth: 2,
      },
    ],
  };

  // Chart options for the line chart
  const optionsForNews = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        title: {
          display: true,
          text: "Clicks",
        },
      },
    },
  };

  // Line Chart #2

  const [countsForReviews, setCountsForReviews] = useState([]);
  const [datesForReviews, setDatesForReviews] = useState([]);

  // Fetch data from your API endpoint
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://mm.magma3c.com/logs/lineGraphForReviews"
        ); // Replace with your API URL
        const data = await response.json();

        // Assuming the backend returns { counts: [72, 100, 50], dates: ['2024-11-18', '2024-11-19', '2024-11-20'] }
        setCountsForReviews(data.counts);
        setDatesForReviews(data.dates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Chart.js data for line chart
  const dataForReviews = {
    labels: datesForReviews, // Dates for x-axis
    datasets: [
      {
        label: "Number Of Clicks On Reviews", // Label for the dataset
        data: countsForReviews, // Record counts for y-axis
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)", // Line color
        tension: 0.1, // Line curve tension (0 for sharp lines)
        borderWidth: 2,
      },
    ],
  };

  // Chart options for the line chart
  const optionsForReviews = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        title: {
          display: true,
          text: "Clicks",
        },
      },
    },
  };

  useEffect(() => {
    if (allMobiles && !allMobiles.length) dispatch(getAllMobiles());
  }, [allMobiles, dispatch]);

  const [totalMobiles, setTotalMobiles] = useState({});

  const [totalMobilesOpen, setTotalMobilesOpen] = useState(false);

  useEffect(() => {
    if (allMobiles.length > 0) {
      const brandCount = {};

      allMobiles.forEach(({ brand }) => {
        if (brandCount[brand]) {
          brandCount[brand] += 1;
        } else {
          brandCount[brand] = 1;
        }
      });


      setTotalMobiles(brandCount);
    }
  }, [allMobiles]);

  const chartData2 = {
    labels: Object.keys(totalMobiles), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
    datasets: [
      {
        label: "Total Mobiles Per Brand",
        data: Object.values(totalMobiles), // Counts of each brand
        backgroundColor: barColors.slice(0, Object.keys(totalMobiles).length), // Apply unique color to each bar
        borderColor: "#36A2EB", // Border color of the bars
        borderWidth: 1,
      },
    ],
  };

  // Chart Options
  const chartOptions2 = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Total Mobiles Per Brand",
      },
      tooltip: {
        callbacks: {
          label: (context) => `Mobiles: ${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: { display: true, text: "Brands" },
      },
      y: {
        beginAtZero: true,
        title: { display: true, text: "Mobiles" },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const clickedBrand = chartData.labels[index];
        const clickedBrandCount = chartData.datasets[0].data[index];
        handleBarClick(clickedBrand, clickedBrandCount);
      }
    },
  };

  const { publishedNews } = useSelector((store) => store.news);

  const [totalNewsOpen, setTotalNewsOpen] = useState(false);

  useEffect(() => {
    if (publishedNews && !publishedNews.length) dispatch(getPublishedNews());
  }, [publishedNews, dispatch]);

  const [totalNews, setTotalNews] = useState({});

  useEffect(() => {
    if (publishedNews.length > 0) {
      const brandCount = {};

      publishedNews.forEach(({ brand }) => {
        if (brandCount[brand]) {
          brandCount[brand] += 1;
        } else {
          brandCount[brand] = 1;
        }
      });


      setTotalNews(brandCount);
    }
  }, [publishedNews]);

  const chartData3 = {
    labels: Object.keys(totalNews), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
    datasets: [
      {
        label: "Total News Per Brand",
        data: Object.values(totalNews), // Counts of each brand
        backgroundColor: barColors.slice(0, Object.keys(totalNews).length), // Apply unique color to each bar
        borderColor: "#36A2EB", // Border color of the bars
        borderWidth: 1,
      },
    ],
  };

  // Chart Options
  const chartOptions3 = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Total News Per Brand",
      },
      tooltip: {
        callbacks: {
          label: (context) => `News: ${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: { display: true, text: "Brands" },
      },
      y: {
        beginAtZero: true,
        title: { display: true, text: "News" },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const clickedBrand = chartData.labels[index];
        const clickedBrandCount = chartData.datasets[0].data[index];
        handleBarClick(clickedBrand, clickedBrandCount);
      }
    },
  };

  // SINGLE AD CLICKS

  useEffect(() => {
    // Get the current date and subtract 10 days
    const now = new Date();
    const tenDaysAgo = new Date(now);
    tenDaysAgo.setDate(now.getDate() - 10);

    // Filter URLs from the last 10 days
    const filtered = adsLogs.filter((item) => {
      const itemDate = new Date(item.activityTime);
      return itemDate >= tenDaysAgo; // Include only items within the last 10 days
    });

    let clicks = filtered.reduce((acc, item) => {
      const url = item.url;
      acc[url] = (acc[url] || 0) + 1; // Increment the count or set to 1 if not already in the accumulator
      return acc;
    }, {});

    setAdsClicks(clicks);
  }, [adsLogs]);

  // Prepare data for the chart
  const labels = Object.keys(adsClicks); // URLs
  const values = Object.values(adsClicks); // Counts

  // Chart configuration
  const chartData4 = {
    labels: labels,
    datasets: [
      {
        label: "URL Visits",
        data: values,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options4 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false, // Hides the x-axis labels (URLs)
          autoSkip: false,
          maxRotation: 90,
          minRotation: 45,
        },
        grid: {
          drawBorder: false, // Hides the x-axis base line
        },
      },
      y: {
        beginAtZero: false,
      },
    },
  };

  // SINGLE NEWS  CLICKS

  useEffect(() => {
    dispatch(getNewsLogs());
  }, []);

  useEffect(() => {
    // Get the current date and subtract 10 days
    const now = new Date();
    const tenDaysAgo = new Date(now);
    tenDaysAgo.setDate(now.getDate() - 10);

    // Filter URLs from the last 10 days
    const filtered = newsLogs.filter((item) => {
      const itemDate = new Date(item.activityTime);
      return itemDate >= tenDaysAgo; // Include only items within the last 10 days
    });

    let clicks = filtered.reduce((acc, item) => {
      const url = item.url;
      acc[url] = (acc[url] || 0) + 1; // Increment the count or set to 1 if not already in the accumulator
      return acc;
    }, {});

    setNewsClicks(clicks);
  }, [newsLogs]);

  // Prepare data for the chart
  const labels2 = Object.keys(newsClicks); // URLs
  const values2 = Object.values(newsClicks); // Counts

  // Chart configuration
  const chartData5 = {
    labels: labels2,
    datasets: [
      {
        label: "URL Visits",
        data: values2,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

 
  const options5 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false, // Hides the x-axis labels (URLs)
          autoSkip: false,
          maxRotation: 90,
          minRotation: 45,
        },
        grid: {
          drawBorder: false, // Hides the x-axis base line
        },
      },
      y: {
        beginAtZero: false,
      },
    },
  };


   //  ADS PER BRAND

     // Define an array of colors for each bar
  const barColors2 = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#FF9F40",
    "#9966FF",
    "#FF3366",
    "#FF33CC",
    "#33FF57",
    "#FF5733",
    "#33B5E5",
    "#FFB74D",
    "#009688",
    "#E91E63",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#00BCD4",
    "#FF5722",
    "#2196F3",
    "#673AB7",
    "#9C27B0",
    "#FF9800",
    "#FFEB3B",
    "#607D8B",
    "#FF4081",
  ];

  // Preparing the data for the Bar chart
  const chartData6 = {
    labels: Object.keys(brandsMobilesCount), // Brand names (e.g., 'TEL', 'SAMSUNG', etc.)
    datasets: [
      {
        label: "Mobile Ads per Brand",
        data: Object.values(brandsMobilesCount), // Counts of each brand
        backgroundColor: barColors.slice(
          0,
          Object.keys(brandsMobilesCount).length
        ), // Apply unique color to each bar
        borderColor: "#36A2EB", // Border color of the bars
        borderWidth: 1,
      },
    ],
  };

  // Chart options for better presentation
  const chartOptions6 = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Mobile Ads per Brand",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `Clicks: ${context.raw}`; // Format the tooltip to show the count of clicks
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Brands",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Ads Count",
        },
      },
    },
  };
  return (
    <div>
      <h1 className="text-blue-950 text-4xl text-center mb-4">
        TOTAL ANALYTICS
      </h1>

      {/* GRAPHS */}

      <div className=" grid grid-cols-1 md:grid-cols-4 lg:grid-cols-3 gap-3">
        <div
          className="shadow bg-white hover:bg-slate-400 flex justify-center items-center rounded-md p-4 cursor-pointer "
          onClick={() => {
            setMobiles(true);
          }}
        >
          <div className="w-full">
            <Bar data={chartData} options={chartOptions} />
            <div className="bg-slate-600 text-white text-center hover:bg-slate-800 ">
              MOBILES CLICKS
            </div>
          </div>
        </div>
        <div
          className="shadow bg-white flex justify-center items-center rounded-md p-4 cursor-pointer"
          onClick={() => {
            setNews(true);
          }}
        >
          <div className="w-full">
            <Line data={dataForNews} options={optionsForNews} />
            <div className="bg-slate-600 text-white text-center hover:bg-slate-800 ">
              NEWS CLICKS
            </div>
          </div>
        </div>
        <div
          className="shadow bg-white flex justify-center items-center rounded-md p-4 cursor-pointer"
          onClick={() => {
            setReviews(true);
          }}
        >
          <div className="w-full">
            <Line data={dataForReviews} options={optionsForReviews} />
            <div className="bg-slate-600 text-white text-center hover:bg-slate-800">
              REVIEWS CLICKS
            </div>
          </div>
        </div>
        <div
          className="shadow bg-white flex justify-center items-center rounded-md p-4 cursor-pointer"
          onClick={() => {
            setAds(true);
          }}
        >
          <div className="w-full h-44  flex justify-between flex-col items-center">
            <div className="w-[100%] h-[80%]">
              <Pie
                data={pieChartData}
                options={{
                  ...options,
                  maintainAspectRatio: false, // Disable maintaining aspect ratio
                }}
                className="w-48 h-48"
              />
            </div>

            <div className="bg-slate-600 text-white text-center w-[100%] hover:bg-slate-800">
              ADS CLICKS
            </div>
          </div>
        </div>

        <div
          className="shadow bg-white hover:bg-slate-400 flex justify-center items-center rounded-md p-4 cursor-pointer "
          onClick={() => {
            setTotalMobilesOpen(true);
          }}
        >
          <div className="w-full">
            <Bar data={chartData2} options={chartOptions2} />
            <div className="bg-slate-600 text-white text-center hover:bg-slate-800 ">
              TOTAL MOBILES
            </div>
          </div>
        </div>
        <div
          className="shadow bg-white hover:bg-slate-400 flex justify-center items-center rounded-md p-4 cursor-pointer "
          onClick={() => {
            setTotalNewsOpen(true);
          }}
        >
          <div className="w-full">
            <Bar data={chartData3} options={chartOptions3} />
            <div className="bg-slate-600 text-white text-center hover:bg-slate-800 ">
              TOTAL NEWS
            </div>
          </div>
        </div>

        <div
          className="shadow bg-white hover:bg-slate-400 flex justify-center items-center rounded-md p-4 cursor-pointer "
          onClick={() => {
            setSingleNews(true);
          }}
        >
          <div className="w-full">
            <Bar data={chartData5} options={options5} />
            <div className="bg-slate-600 text-white text-center hover:bg-slate-800 ">
              SINGLE NEWS CLICKS
            </div>
          </div>
        </div>

        <div
          className="shadow bg-white hover:bg-slate-400 flex justify-center items-center rounded-md p-4 cursor-pointer "
          onClick={() => {
            setSingleAd(true);
          }}
        >
          <div className="w-full">
            <Bar data={chartData4} options={options4} />
            <div className="bg-slate-600 text-white text-center hover:bg-slate-800 ">
              SINGLE AD CLICKS
            </div>
          </div>
        </div>
      </div>

      {mobiles && (
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => {
            setMobiles(false);
            setSecondGraph(false);
          }}
        >
          <div className="p-6 bg-white w-[100%]  md:w-[50%] ">
            {/* <Link to={"/logs"}> */}
            {secondGraph && (
              <Bar data={charDataForSingleBar} options={optionsForSingleBar} />
            )}
            {!secondGraph && <Bar data={chartData} options={chartOptions} />}

            {/* </Link> */}
          </div>
        </div>
      )}

      {ads && (
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => setAds(false)}
        >
          {/* No Of Clicks On Ads */}

          <div className="p-6 bg-white w-[100%] md:w-[50%] md:h-[80%] flex justify-center items-center">
            {/* <Link to={"/logs"}> */}
            <Pie data={pieChartData} options={options} />
            {/* </Link> */}
          </div>
        </div>
      )}

      {news && (
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => setNews(false)}
        >
          {/* Displaying the Line Chart */}

          <div className="p-6 bg-white w-[100%]  md:w-[50%]">
            {/* <Link to={"/logs"}> */}
            <Line data={dataForNews} options={optionsForNews} />
            {/* </Link> */}
          </div>
        </div>
      )}

      {reviews && (
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => setReviews(false)}
        >
          <div className="p-6 bg-white w-[100%]  md:w-[50%]">
            {/* <Link to={"/logs"}> */}
            <Line data={dataForReviews} options={optionsForReviews} />
            {/* </Link> */}
          </div>
        </div>
      )}

      {totalMobilesOpen && (
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => setTotalMobilesOpen(false)}
        >
          <div className="p-6 bg-white w-[100%]  md:w-[50%]">
            {/* <Link to={"/logs"}> */}
            <Bar data={chartData2} options={chartOptions2} />
            {/* </Link> */}
          </div>
        </div>
      )}

      {totalNewsOpen && (
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => setTotalNewsOpen(false)}
        >
          <div className="p-6 bg-white w-[100%]  md:w-[50%]">
            {/* <Link to={"/logs"}> */}
            <Bar data={chartData3} options={chartOptions3} />
            {/* </Link> */}
          </div>
        </div>
      )}

      {singleAd && (
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => {
            setSingleAd(false);
          }}
        >
          <div className="p-6 bg-white w-[100%]  md:w-[50%]">
            {/* <Link to={"/logs"}> */}
            <Bar data={chartData4} options={options4} />
            {/* </Link> */}
          </div>
        </div>
      )}

      {singleNews && (
        <div
          className="bg-black/90 w-screen h-screen z-50 absolute inset-0 flex flex-col justify-center items-center cursor-pointer"
          onClick={() => {
            setSingleNews(false);
          }}
        >
          <div className="p-6 bg-white w-[100%]  md:w-[50%]">
            {/* <Link to={"/logs"}> */}
            <Bar data={chartData5} options={options5} />
            {/* </Link> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default MainDashboard;
