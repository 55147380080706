import React from 'react'

const AdsCard = (item) => {
  return (
    <div className="fixed inset-0 z-50 bg-black/70" onClick={()=>item.toggle()}>
    
      <div
        className="border fixed left-[400px] z-20 border-gray-400 bg-gray-200 rounded mt-5 w-[50vw] md:w-[50vw] h-[auto]  p-2"
      >
        {item.images && item.images.length > 0 ? (
  <img 
  className="h-[120px] w-[120px] object-contain m-auto mt-2 mb-6 bg-transparent "
    src={item.images[0]}
    alt={item.title || "Image"}
  />
) : (
  <img
    className="h-[120px] w-[120px] object-contain m-auto mt-2 mb-6"
    src="/path/to/placeholder-image.jpg"
    alt="Ad"
  />
)}
<div className='flex p-14'>
<div className='inline-block'>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Price</b>: {item.price}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Brand</b>: {item.brand}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Title</b>: {item.title}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Area</b>: {item.area}, {item.city}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Lat</b>: {item.lat}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Long</b>: {item.long}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Country</b>: {item.country}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>UserId</b>: {item.uid}
        </p>
        </div> 
        <div className='inline-block' >
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Condition</b>: {item.condition}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Description</b>: {item.description}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Published</b>: {item.publishedOn}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Mobile</b>: {item.mobileNo}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Likes</b>: {item.likes.length}
        </p>
        <p className="text-[16px] mt-[2px] p-[3px]">
          <b>Views</b>: {item.views.length}
        </p>
        </div>
      </div>
      </div>
   
  </div>
  )
}

export default AdsCard
