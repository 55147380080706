
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postNotify,
  fetchAllNotifications,
  selectNotifications,
} from "../../../Redux/Notification";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import { IoArrowBackOutline } from "react-icons/io5";
import ImageUpload from "../../ImageUpload1";

const Notifications = () => {
  
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const allNotifications = useSelector(selectNotifications);

  const dispatch = useDispatch();

  // Fetch notifications on component mount
  useEffect(() => {
    dispatch(fetchAllNotifications());
  }, [dispatch]);

  // Prepare columns for DataGrid
  const columns = [
    // { field: "id", headerName: "ID", width: 70 },
    { field: "title", headerName: "Title", width: 250 },
    { field: "text", headerName: "Text", width: 250 },
    { field: "country", headerName: "Country", width: 130 },
    { field: "city", headerName: "City", width: 130 },
    { field: "imgURL", headerName: "Image URL", width: 130 },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      country: selectedCountry ? selectedCountry.value : "",
      city: selectedCity ? selectedCity.value : "",
      title,
      text,
      imgURL: "",
    };

    // Dispatch action to post notification
    dispatch(postNotify(data));

    toast.success("Notification Sent Successfully!");

    setDisplayForm(false);
  };

  // Fetch all countries on component mount
  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countryOptions = response.data.map((country) => ({
          value: country.name.common,
          label: country.name.common,
        }));
        setCountries(countryOptions);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  // Fetch cities when a country is selected
  useEffect(() => {
    if (!selectedCountry) return;

    axios
      .post("https://countriesnow.space/api/v0.1/countries/cities", {
        country: selectedCountry.value,
      })
      .then((response) => {
        if (
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data)
        ) {
          const cityOptions = response.data.data.map((city) => ({
            value: city,
            label: city,
          }));
          setCities(cityOptions);
        } else {
          setCities([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
        setCities([]); // Reset cities in case of error
      });
  }, [selectedCountry]);

  // Handle the city selection change
  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  const rowsToDisplay = allNotifications.map((item) => ({
    id: item._id,
    title: item.title,
    text: item.text,
    country: item.country,
    city: item.city,
    imgURL: item.imgURL,
  }));

  return (
    <>
      {displayForm && (
        <div className="z-10 bg-black/80 inset-0 w-full h-screen absolute flex flex-col justify-center items-center p-3">
          <IoArrowBackOutline
            className="float-left text-5xl self-start text-white cursor-pointer"
            onClick={() => setDisplayForm(false)}
          />
          <div className="border-gray-700 rounded shadow-2xl w-[40%] p-7 h-[85vh] bg-white">
            <h1 className="text-center font-bold text-gray-700 text-[23px]">
              NOTIFICATIONS
            </h1>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-evenly items-center h-[90%]"
            >
              <div className="w-full h-[12vh] flex flex-col justify-between items-center">
                <label className="font-semibold text-gray-800 text-sm text-left w-full">
                  Select Country
                </label>
                <Select
                  options={countries}
                  value={selectedCountry}
                  onChange={setSelectedCountry}
                  placeholder="Select a country"
                  className="w-full"
                  required
                />
              </div>

              {selectedCountry && (
                <div className="w-full h-[12vh] flex flex-col justify-between items-center">
                  <label className="font-semibold text-gray-800 text-sm text-left w-full">
                    Select City
                  </label>
                  <Select
                    options={cities}
                    value={selectedCity}
                    onChange={handleCityChange}
                    placeholder="Select a city"
                    className="w-full"
                  />
                </div>
              )}

              <div className="w-full flex flex-col h-[12vh] justify-between items-center">
                <label className="font-semibold text-gray-800 text-sm w-full">
                  Title <span className="text-red-600 font-bold">*</span>
                </label>
                <input
                  required
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Please add title"
                  className="focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-sm border-1 p-2 border-gray-400 w-full"
                />
              </div>

              <div className="w-full flex flex-col h-[20vh] justify-between items-center">
                <label className="font-semibold text-gray-800 text-sm w-full">
                  Description <span className="text-red-600 font-bold">*</span>
                </label>
                <textarea
                  required
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  rows="4"
                  cols="50"
                  placeholder="Please add description"
                  className="focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-sm border-1 border-gray-400 h-[70%] w-full"
                />
              </div>

              <ImageUpload />

              <button
                type="submit"
                className="bg-[#1F2937] w-full h-10 hover:bg-[#3b506e] text-white"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="flex justify-end">
        <button
          onClick={() => setDisplayForm(true)}
          className="bg-[#1F2937] text-sm p-2 mb-2 hover:bg-[#3b506e] text-white rounded-md"
        >
          New Notification <AddIcon />
        </button>
      </div>

      <div>
        <Paper sx={{ height: 750, width: "85%" }}>
          <DataGrid
            rows={rowsToDisplay}
            columns={columns}
            pageSizeOptions={[5, 10, 20]}
            paginationModel={{
              page: currentPage - 1,
              pageSize: 15,
            }}
            onPaginationModelChange={(model) => setCurrentPage(model.page + 1)}
            
          />
        </Paper>
      </div>
    </>
  );
};

export default Notifications;
