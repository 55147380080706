
import React from "react";
import profile from "./images/image.png"; // Placeholder profile image

const Carddummy = (props) => {
  return (
    <div className="fixed  flex justify-center inset-0 z-50 bg-black/70">
    <div className="overflow-y-auto text-sm fixed top-[15%] center bg-gray-100 rounded z-10 w-[550px] h-[70vh] border border-gray-400 flex flex-col space-y-4 p-4">
      


    
      <button className="w-[400px] p-2 bg-gray-700  text-white m-auto mt-0 rounded" onClick={()=>{
        props.toggle()
       
      }} >close</button>
    
      {props.img === " " ? (
        <img
          className="m-auto mt-0 w-[120px] h-[120px] rounded-full"
          src={profile}
          alt="profile"
        />
      ) : (
        <img
          className="m-auto mt-0 w-[120px] h-[120px] rounded-full"
          src={props.img} // Use actual image
          alt={`${props.name}'s Profile`}
        />
      )}
          <p className="m-auto mt-0 font-bold text-xl ">{props.name}</p>
<div className="m-auto mt-0  text-xl flex gap-4 " >
       <span className="text-gray-600"> <b>Email:</b> {props.email}</span>
      <span className="text-gray-600 "> <b>Reports:</b> <span className="text-red-600 font-bold">{props.reports}</span></span>
     
      </div>

    </div>
    </div>
  );
};

export default Carddummy;

