// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";

const getUserFromLocalStorage = () => {
  try {
    let user = localStorage.getItem("user");

    if (user) {
      user = JSON.parse(user);
    } else {
      user = null;
    }
    return user;
  } catch (error) {
    console.error("Error parsing user from localStorage", error);
    return null;
  }
};

const getTokenFromLocalStorage = () => {
  try {
    let token = localStorage.getItem("token");

    if (token) {
      token = JSON.parse(token);
    } else {
      token = null;
    }
    return token;
  } catch (error) {
    console.error("Error parsing user from localStorage", error);
    return null;
  }
};

const UserReducer = createSlice({
  //STATE NAME
  name: "users",

  //STATE INITIAL VALUE
  initialState: {
    currentUser: getUserFromLocalStorage(),
    token: getTokenFromLocalStorage(),
    singleUser: null,
    message: "",
    status: "idle",
    isEdited: false,
    error: null,
  },

  //REDUCER FUNCTIONS
  reducers: {
    loginRequest(state) {
      state.status = "loading";
    },
    loginSuccess(state, action) {
      state.status = "succeeded";
      state.currentUser = action.payload.currentUser;
      localStorage.setItem("user", JSON.stringify(action.payload.currentUser));
      state.token = action.payload.token;
      localStorage.setItem("token", JSON.stringify(action.payload.token));
    },
    logoutRequest(state) {
      state.status = "loading";
    },
    logoutSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("currentPage");
      localStorage.removeItem("Brand");
      window.location.reload("/");
    },
    signupRequest(state) {
      state.status = "loading";
    },
    signupSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.isEdited = true;
    },
    signupError(state, action) {
      state.status = "failure";
      state.message = action.payload.message;
    },
    singleUserRequest(state) {
      state.status = "loading";
    },
    singleUserSuccess(state, action) {
      state.status = "succeeded";
      state.singleUser = action.payload.user;
    },
    updateSingleUserRequest(state) {
      state.status = "loading";
    },
    updateSingleUserSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.isEdited = true;
    },

    deleteUserRequest(state) {
      state.status = "loading";
    },
    deleteUserSuccess(state, action) {
      state.status = "succeeded";
      state.message = action.payload.message;
      state.isEdited = true;
    },
    loginFailure(state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
    resetErrors(state) {
      state.status = "idle";
    },
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const {
  loginRequest,
  loginSuccess,
  logoutRequest,
  logoutSuccess,
  signupRequest,
  signupSuccess,
  signupError,
  singleUserRequest,
  singleUserSuccess,
  updateSingleUserRequest,
  updateSingleUserSuccess,
  loginFailure,
  deleteUserSuccess,
  deleteUserRequest,
  resetErrors,
} = UserReducer.actions;

//EXPORT REDUCER

export default UserReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const login = (uid) => async (dispatch) => {
  dispatch(loginRequest());

  const response = await fetch(`https://mm.magma3c.com/staff/login`, {
    method: "POST",
    body: JSON.stringify(uid),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();


  dispatch(
    loginSuccess({ currentUser: responseData.user, token: responseData.token })
  );
};

export const signup = (user) => async (dispatch) => {
  dispatch(signupRequest());

  const response = await fetch(`https://mm.magma3c.com/staff/signup`, {
    method: "POST",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();

  if (!response.ok) {
    dispatch(signupError({ message: responseData.message }));
  } else {
    dispatch(signupSuccess({ message: responseData.message }));
  }
};

export const getSingleUser = (id) => async (dispatch) => {
  dispatch(singleUserRequest());

  const response = await fetch(`https://mm.magma3c.com/staff/singleUser/${id}`);

  const responseData = await response.json();


  dispatch(singleUserSuccess({ user: responseData.user }));
};

export const updateSingleUser = (id, data) => async (dispatch) => {

  dispatch(updateSingleUserRequest());

  const response = await fetch(`https://mm.magma3c.com/staff/update/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const responseData = await response.json();

  dispatch(updateSingleUserSuccess({ message: responseData.message }));
};

export const deleteUser = (uid, docId) => async (dispatch) => {

  dispatch(deleteUserRequest());

  const response = await fetch(
    `https://mm.magma3c.com/staff/delete?ids=${uid},${docId}`,
    {
      method: "DELETE",
    }
  );

  const responseData = await response.json();


  dispatch(deleteUserSuccess({ message: responseData.message }));
};

export const logout = (uid) => async (dispatch) => {
  dispatch(logoutRequest());

  const response = await fetch(`https://mm.magma3c.com/staff/logout`, {
    method: "POST",
    body: JSON.stringify({ uid }),
    headers: { "Content-Type": "application/json" },
  });

  const responseData = await response.json();

  dispatch(logoutSuccess({ message: responseData.message }));
};
