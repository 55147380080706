import React, { useContext, useState , useEffect} from "react";

import { IoMdPersonAdd } from "react-icons/io";
import UsersCategories from "./UsersCategories";
import UsersDetails from "./UsersDetails";
import UserAddForm from "./UserAddForm";
import { UserConetxt } from "./UserProvider";
import UserEditForm from "./UserEditForm";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {resetErrors} from "../../../Redux/UserReducer";

const Users = () => {

  const dispatch = useDispatch();

  const [isFormVisible, setFormVisible] = useState(false);

  const { users, setUsers, todo, setTodo, editTodo, setEdittodo } =
    useContext(UserConetxt);

  const handleFormVisible = () => {
    setFormVisible(!isFormVisible);
  };


  const {status,message}=useSelector(state=>state.user)

  useEffect(()=>{
    if(status === "failure") toast.error(message);
    dispatch(resetErrors())
  },[message,status]);

  const handleDelete = (index) => {
    const updatedtodos = todo.filter((_, i) => i !== index);
    setTodo(updatedtodos);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsers({ ...users, [name]: value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!users.name || !users.email) return;

    const updatedTodos =
      editTodo !== null
        ? todo.map((item, index) => (index === editTodo ? users : item))
        : [...todo, users];

    setTodo(updatedTodos);
    setUsers({ name: "", email: "", role: "" });
    setEdittodo(null);
    setFormVisible(false);
  };

  return (
    <div>
   

      <div className=" text-xs mt-1 overflow-y-hidden relative 2xl:ml-10">
        <div className="flex justify-between">
          <div>
            <h1 className="text-blue-900 font-semibold 2xl:text-[3rem] text-xl">
              User's
            </h1>
            <p className="2xl:text-2xl 2xl:text-gray-800 2xl:font-semibold text-xs text-gray-500 2xl:my-1">
              You can find all users here
            </p>
          </div>
          <button
            onClick={handleFormVisible}
            className="2xl:w-20  w-9 2xl:h-20 h-9 text-center 2xl:p-2 text-white bg-gray-700 rounded-md"
          >
            <IoMdPersonAdd size={23} className="m-1 2xl:hidden flex" />
            <IoMdPersonAdd size={50} className="m-1 2xl:flex hidden" />
          </button>
        </div>

        {/* USERS CATORIES STARTS */}

        <UsersCategories />

        {/* USERS CATORIES ENDS */}

        {/* USERS DETAILS STARTS */}

        <UsersDetails
          todo={todo}
          handleDelete={handleDelete}
         
        />

        {/* USERS DETAILS ENDS */}

        {isFormVisible && (
          <UserAddForm
            handleFormVisible={handleFormVisible}
            submitForm={submitForm}
            handleChange={handleChange}
            users={users}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
