// SLICER AND REDUCERS ARE WRITTEN HERE

import { createSlice } from "@reduxjs/toolkit";


const UsersReducer = createSlice({
  //STATE NAME
  name: "users",

  //STATE INITIAL VALUE
  initialState: {
    message: "",
    status: "idle",
    error: null,
    users: [],
  },

  //REDUCER FUNCTIONS
  reducers: {
    usersRequest(state) {
      state.status = "loading";
    },
    usersSuccess(state, action) {
      state.status = "succeeded";
      state.users = action.payload.users;
    },
    usersFailure(state,action) {
      state.status = "loading";
      state.error = action.payload.error;
    },
  },
});

//EXPORT REDUCER ACTION FUNCTIONS

export const { usersRequest, usersSuccess, usersFailure } = UsersReducer.actions;

//EXPORT REDUCER

export default UsersReducer.reducer;

//ACTION FUNCTIONS -- THESE WILL BE CALLED INSIDE OUR REACT COMPONENTS

export const getUsers = () => async (dispatch) => {
  dispatch(usersRequest());

  try {
    const response = await fetch(`https://mm.magma3c.com/staff/allUsers`);

    const responseData = await response.json();

    dispatch(usersSuccess({ users: responseData.users }));
  } catch (error) {
    dispatch(usersFailure(error.message));
  }
};
