import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Loader from "react-js-loader";
import { useSelector, useDispatch } from "react-redux";
import { getPendingNews } from "../../../Redux/NewsReducer";
import { updateSingleNews } from "../../../Redux/NewsReducer";

const PendingNews = () => {

  const dispatch = useDispatch();

  const { pendingNews, status } = useSelector((state) => state.news);

  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getPendingNews());
  }, []);

  function approveOrReject(id, value) {

    dispatch(updateSingleNews(id, value));

  }

  const settings = {
    infinite: false,
    speed: 700,
    slidesToShow: Math.min(3, pendingNews.length),
    slidesToScroll: 2,
    autoplay: false,
  };

  return (
    <div className=" px-3 mt-4 rounded-md space-y-3 h-[300px] py-3 border  border-gray-700 my-3 bg-white shadow-md shadow-gray-500">
      <div className="flex justify-between">
        <h1 className="2xl:text-[2rem] font-semibold text-gray-600 mb-2">
          Pending News
        </h1>
        <Link
          className="2xl:text-[2rem] text-sm underline text-blue-500 font-semibold px-4 2xl:py-2"
          to="/allPendingNews"
        >
          View all
        </Link>
      </div>
      {status == "loading" ? (
        <div className="h-full py-11">
          <Loader type="bubble-scale" bgColor={"#AEB9B8"} size={50} />
        </div>
      ) : (
        <Slider {...settings}>
          {pendingNews &&
            pendingNews.map((news, index) => (
              <div key={index}>
                
                <Link to={`/newsdetails/${news.title}/${news._id}`}>
                  <div className=" space-x-3 ml-2 hover:scale-105 duration-200  rounded-lg 2xl:h-[340px] md:h-[170px] 2xl:w-[540px] lg:w-[300px] md:w-[200px] h-36  relative">
                    <img
                      src={news.image1}
                      alt="news images"
                      className="relative rounded-lg 2xl:w-[540px] lg:w-[300px] md:w-[200px] inset-0 2xl:h-[320px] md:h-[170px] object-cover bg-no-repeat shadow-md"
                    />
                    <div className="2xl:w-[480px] lg:w-[320px] md:w-[200px] 2xl:text-[1.8rem] line-clamp-2 text-[0.9rem]  md:pt-[6rem] 2xl:pt-[12rem] pt-[4.5rem] absolute inset-0 z-50  text-white overflow-x-hidden px-2">
                      <h1>{news.title}</h1>
                    </div>
                  </div>
                </Link>

                {currentUser.role == "admin" && (
                  <div className="2xl:w-[540px] lg:w-[300px] md:w-[200px]  2xl:h-[70px] flex mt-3 ml-[9px] mr-4 space-x-3 2xl:text-[1.8rem] text-[13px] font-semibold justify-between ">
                    <button
                      className="w-full px-4 rounded-md  py-2 text-center bg-gray-800 text-white items-center duration-300 hover:scale-105"
                      onClick={() => approveOrReject(news._id, "successful")}
                    >
                      Approve
                    </button>
                    <button
                      className="w-full bg-gray-800 text-white px-4 rounded-md py-2 text-center items-center duration-300 hover:scale-105"
                      onClick={() => approveOrReject(news._id, "Rejected")}
                    >
                      Reject
                    </button>
                  </div>
                )}
              </div>
            ))}
        </Slider>
      )}
    </div>
  );
};

export default PendingNews;
